import { Badge, Checkbox, FormControlLabel, Grid, Rating, Typography } from "@mui/material";
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import classes from "../../Assets/Styles/QAScreen.module.css";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SquareIcon from "@mui/icons-material/Square";
import Loader from "../../Components/Loader";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Store } from "../../Store";
import {
  ADD_ONE_QUALITYASSURANCE_NOTE,
  CREATE_MULTIPLE_AUDIO,
  CREATE_NEW_AUDIO,
  DELETE_ONE_QUALITYASSURANCE_NOTE,
  GET_ALL_QUALITYASSURANCE_NOTES,
  GET_ALL_QUALITYASSURANCE_VERSIONS,
  GET_CHARACTERS_FOR_PROJECT,
  GET_SPEECH_FOR_PROJECT_BY_PAGE,
  GET_SPEECH_POSITION_AROUND_TIME,
  UPDATE_ONE_QUALITYASSURANCE_NOTE,
  UPDATE_ONE_SPEECH,
  SRT,
  GET_SRT
} from "../../MProjectConst";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  convertToDate,
  formatTime,
  timeStringToSeconds,
} from "../../utils/generalFunctions";
import QAVideoSlider from "../../Components/AudioTools/QAVideoSlider";
import { primaryColor, secondaryColor } from "../../utils/constants";
import CopyLinkButton from "../../Components/CopyLinkButton";
import TaskButton from "../../Components/Buttons/TaskButton";
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import ReactAudioPlayer from "react-audio-player";
import GlobalAudioPlayer from "../../Components/AudioTools/AudioPlayerComponents/GlobalAudioPlayer";
import UnifiedVideoPlayer from "../../Components/AudioTools/UnifiedVideoPlayer";


const QAScreen = () => {
  const params = useParams();
  const { language: languageCode } = params;
  const { state } = useContext(Store);
  const { projectFile, userInfo } = state;
  const audioRefs = useRef([]);
  const [characters, setCharacters] = useState([]);
  const [versionQA, setVersionQA] = useState();
  const [audioData, setAudioData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [audioLoader, setAudioLoader] = useState(false);

  const [currentTime, setCurrentTime] = useState(0);
  const [seekTime, setSeekTime] = useState(0);
  const [seekTimeIndex, setSeekTimeIndex] = useState(0);
  const [scrollableItem, setScrollableItem] = useState(0);
  const [scrollableItemLastUpdated, setScrollableItemLastUpdated] = useState(0);
  // const [audioLoader] = useState(false);
  const [dubbedVideosList, setDubbedVideosList] = useState([]);
  const [videoSource, setVidoSource] = useState(projectFile.videoUrl);
  const [videoUniqueId, setVideoUniqueId] = useState("");
  const [videoVersion, setVideoVersion] = useState("");
  const [pausePlay, setPausePlay] = useState(false);

  const containerRef = useRef(null);
  const timeoutIdRef = useRef(null);

  const numberOfDialogues = projectFile.numberOfDialogues;
  // pagination items
  const [pageButtonActivity, setPageButtonActivity] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [goToPageNumber, setGoToPageNumber] = useState("");
  const itemsPerPage = 10;
  const totalPages = Math.ceil(numberOfDialogues / itemsPerPage);

  const [srtDownloadLoading, setSrtDownloadLoading] = useState(false);
  const [srtDownloadError, setSrtDownloadError] = useState(null);

  //-------------------------------------

  const [subtitles, setSubtitles] = useState([]);
  const [isLoadingSubtitles, setIsLoadingSubtitles] = useState(false);
  const [subtitleError, setSubtitleError] = useState(null);

  useEffect(() => {
    scrollToItem(scrollableItem - 1);
  }, [scrollableItem]);

  useEffect(() => {
    checkTimeInRange(currentTime);
  }, [currentTime]);

  const scrollToItem = (index) => {
    const manpIndex = index;

    if (containerRef.current) {
      const item = containerRef.current.children[manpIndex];
      if (item) {
        item.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const displayAudioTime = useCallback((data) => {
    setCurrentTime(data);
  }, []);

  function checkTimeInRange(currentTime) {
    if (audioData) {
      const itemInTimeRange = audioData.find((item) => {
        // const { start, end } = item.textdetails;
        return currentTime >= item.start && currentTime < item.end;
      });

      if (itemInTimeRange) {
        if (itemInTimeRange.id !== scrollableItemLastUpdated) {
          setScrollableItemLastUpdated(itemInTimeRange.id);
          setScrollableItem(itemInTimeRange.id);
        }
      }
    }
  }

  //+ Text api for dialogue
  function handleTextChange(e, index) {
    const updatedData = [...versionQA];
    updatedData[index] = {
      ...updatedData[index],
      description: e.target.value,
    };

    setVersionQA(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_QUALITYASSURANCE_NOTE, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }

  //+ Start time api for dialogue
  function handleStartTimeChange(e, index) {
    const updatedData = [...versionQA];
    updatedData[index] = {
      ...updatedData[index],
      start: timeStringToSeconds(e.target.value),
    };

    setVersionQA(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_QUALITYASSURANCE_NOTE, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }
  //+ End time api for dialogue
  function handleEndTimeChange(e, index) {
    const updatedData = [...versionQA];
    updatedData[index] = {
      ...updatedData[index],
      end: timeStringToSeconds(e.target.value),
    };

    setVersionQA(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_QUALITYASSURANCE_NOTE, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 2300);
  }

  //general qa
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_ALL_QUALITYASSURANCE_VERSIONS, {
          projectId: projectFile.id,
          languageCode: languageCode,
        });

        const languageFilteration = response.data.dubbedVideosList?.filter(
          (item) => item.language === languageCode
        );

        // console.log(languageFilteration);
        // setVersionQA(sortedArray);
        setDubbedVideosList(languageFilteration);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  function fetchQaulityAssuranceVersion(uniqueId) {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_ALL_QUALITYASSURANCE_NOTES, {
          projectId: projectFile.id,
          uniqueId: uniqueId,
        });

        const sortedArray = await response.data.projectsArray.sort((a, b) => {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          if (a.end < b.end) return -1;
          if (a.end > b.end) return 1;

          return 0;
        });

        sortedArray.forEach((dia, index) => {
          dia.add = false;
          dia.id = index;
        });

        // console.log(sortedArray);

        setVersionQA(sortedArray);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }

  const findTransObjectsbyLangCode = (array) => {
    const index = array.find((obj) => obj.languageCode === languageCode);
    return index;
  };

  const findDefaultEmotion = (speaker, originalAudioEmotion) => {
    const character = projectFile?.characters?.find(
      (char) => char.speaker === speaker
    );
    const characterVoice = character?.voices?.find(
      (voice) => voice.emotion === originalAudioEmotion
    );
    if (!characterVoice) {
      return "";
    }
    return characterVoice.voice_id;
  };

  function handleNoteChange(e, index) {
    const updatedData = [...audioData];

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedAudioNote: e.target.value,
          };
        } else {
          return prev;
        }
      }
    );
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);

    setAudioData(updatedData);
  }
  function findDisplayName(speaker) {
    const result = characters.find((char) => char.speaker === speaker);

    if (result?.displayName) {
      return result.displayName;
    }
    return speaker;
  }
  function checkTranslationLength(originalLength, translatedLength) {
    if (!translatedLength) {
      return "Result: ";
    }

    // Calculate the allowed range (18% of the original length)
    const allowedDifference = originalLength * 0.18;

    // Calculate the lower and upper bounds for the translated length
    const lowerBound = originalLength - allowedDifference;
    const upperBound = originalLength + allowedDifference;

    // Check if the translated length is within the allowed range
    if (translatedLength >= lowerBound && translatedLength <= upperBound) {
      return "Result: duration is perfect";
    } else if (translatedLength < lowerBound) {
      return <font style={{ color: "red" }}>Result: make it longer.</font>;
    } else {
      return <font style={{ color: "red" }}>Result: make it shorter.</font>;
    }
  }
  const handleVoiceOverLapping = (index, originalDuration) => {
    const updatedData = [...audioData];
    const endTime = updatedData[index].start + originalDuration;

    const startTime = updatedData[index + 1]?.start;

    return endTime >= startTime ? <>{" Overlapping "}</> : "";
  };
  const handleTranslatedAudioVolume = async (e, index) => {
    const dialogueCopy = audioData[index];

    let volume;
    const data = e.target.value;

    if (isNaN(data)) {
      volume = null;
    } else {
      volume = Number(data);
    }

    dialogueCopy.translations = dialogueCopy.translations.map((prev) => {
      if (prev.languageCode === languageCode) {
        return {
          ...prev,
          translatedAudioVolume: volume,
        };
      } else {
        return prev;
      }
    });

    try {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: dialogueCopy,
      });

      // Use functional state update to safely modify the state
      setAudioData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = dialogueCopy;
        return updatedData;
      });

      toast.info(" Audio has been updated", {
        hideProgressBar: false,
        autoClose: 1000,
      });
      // setAudioLoader(false);
    } catch (error) {
      console.error(error);
      setAudioData((prevData) => {
        const updatedData = [...prevData];
        return updatedData;
      });
    }
  };

  function handleTranslationChange(e, index) {
    const updatedData = [...audioData];
    // updatedData[index].translations = {
    //   ...updatedData[index].translations,
    //   translatedText: e.target.value,
    // };

    updatedData[index].translations = updatedData[index].translations.map(
      (prev) => {
        if (prev.languageCode === languageCode) {
          return {
            ...prev,
            translatedText: e.target.value,
          };
        } else {
          return prev;
        }
      }
    );

    setAudioData(updatedData);
    clearTimeout(timeoutIdRef.current);

    timeoutIdRef.current = setTimeout(async () => {
      await axios.post(UPDATE_ONE_SPEECH, {
        projectId: projectFile.id,
        dialogue: updatedData[index],
      });
    }, 1650);
  }

  const nextPrevPageAPI = async (action) => {
    setPageButtonActivity(true);
    if (pageNumber === 1 && action === "previous") {
      return;
    }
    try {
      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: action,
        pageName: "voiceqa",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [
              {
                url: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudio,
                length: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudioDuration,
                emotion: "original",
              },
            ]
            : [];
        });

        setAudioData(dialogues);
        if (action === "next") {
          setPageNumber(Number(pageNumber) + 1);
        } else if (action === "previous") {
          setPageNumber(Number(pageNumber) - 1);
        }
        setPageButtonActivity(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  const goToPageAPI = async (number) => {
    setLoader(true);

    try {
      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "voiceqa",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [
              {
                url: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudio,
                length: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudioDuration,
                emotion: "original",
              },
            ]
            : [];
        });

        setAudioData(dialogues);
        setGoToPageNumber("");
        setPageNumber(number);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  const goToPageAccordingTimeAPI = async () => {
    setLoader(true);

    try {
      const positionResponse = await axios.post(
        GET_SPEECH_POSITION_AROUND_TIME,
        {
          projectId: projectFile.id,
          time: currentTime,
        }
      );

      const number = Math.ceil(positionResponse.data.firstPosition / 10);

      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "voiceqa",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [
              {
                url: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudio,
                length: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudioDuration,
                emotion: "original",
              },
            ]
            : [];
        });

        setAudioData(dialogues);
        setGoToPageNumber("");
        setPageNumber(number);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error in getting the next page");
    }
  };

  const checkLanguage = (translations) => {
    const updatedTranslations = [...translations];
    const existingLanguageCodes = translations.map(
      (transLang) => transLang.languageCode
    );

    projectFile.finalLang.forEach((langCode) => {
      if (!existingLanguageCodes.includes(langCode)) {
        updatedTranslations.push({
          languageCode: langCode,
          translatedText: "",
          translatedAudioList: [],
          translatedAudio: "",
          translatedAudioRating: "",
          translatedAudioNote: null,
          translatedAudioPath: null,
          translatedRecording: "",
        });
      }
    });

    // Return the updated translations array
    return updatedTranslations;
  };

  const handleDownloadSRT = async () => {
    try {
      setSrtDownloadLoading(true);
      setSrtDownloadError(null);

      // Get the URL from your API
      const response = await axios.post(SRT, {
        projectId: projectFile.id,
        language: languageCode
      });

      // Fetch the file content from the URL
      const fileResponse = await axios.get(response.data.url, {
        responseType: 'blob' // Important: tells axios to handle response as binary data
      });

      // Create a blob URL from the file content
      const blob = new Blob([fileResponse.data], { type: 'text/plain' });
      const blobUrl = window.URL.createObjectURL(blob);

      // Create and trigger download
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = response.data.filename;//`srt-${languageCode}.srt`;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl); // Free up memory

      toast.success('SRT file downloaded successfully');
    } catch (err) {
      setSrtDownloadError(err.message);
      toast.error(`Error downloading SRT file ${err.response?.data?.error || ''}`);
      console.error('Error downloading SRT:', err);
    } finally {
      setSrtDownloadLoading(false);
    }
  };


  //apis - get characters
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(GET_CHARACTERS_FOR_PROJECT, {
          projectId: projectFile.id,
        });

        setCharacters(response.data.characters);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [projectFile.id]);

  //apis - dialogue
  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
          userId: userInfo.id,
          projectId: projectFile.id,
          action: "first",
          Current: null,
          pageName: "voiceqa",
        });

        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [
              {
                url: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudio,
                length: findTransObjectsbyLangCode(dia.translations)
                  ?.translatedAudioDuration,
                emotion: "original",
              },
            ]
            : [];
        });

        setAudioData(dialogues);
        setLoader(false);
      } catch (err) {
        console.log(err);
        setLoader(false);
      }
    };
    fetchData();
  }, []);

  const handleTimeSync = async (time) => {
    try {
      setLoader(true);
      const positionResponse = await axios.post(GET_SPEECH_POSITION_AROUND_TIME, {
        projectId: projectFile.id,
        time: time
      });

      const number = Math.ceil(positionResponse.data.firstPosition / 10);

      const response = await axios.post(GET_SPEECH_FOR_PROJECT_BY_PAGE, {
        userId: userInfo.id,
        projectId: projectFile.id,
        action: "goto",
        pageNumber: number,
        pageName: "voiceqa",
      });

      if (response.data) {
        const dialogues = await response.data.projectsArray;

        dialogues.forEach((dia, index) => {
          dia.id = index;
          dia.tempTransEmotion = findDefaultEmotion(
            dia.speaker,
            dia.originalAudioEmotion
          );
          dia.translatedAudioDurationTemp = null;
          dia.record = false;
          dia.translations = checkLanguage(dia.translations);
          dia.filterShow = true;
          dia.loader = false;
          dia.tempAudioList = findTransObjectsbyLangCode(dia.translations)
            ?.translatedAudio
            ? [{
              url: findTransObjectsbyLangCode(dia.translations)?.translatedAudio,
              length: findTransObjectsbyLangCode(dia.translations)?.translatedAudioDuration,
              emotion: "original",
            }]
            : [];
        });

        setAudioData(dialogues);
        setGoToPageNumber("");
        setPageNumber(number);
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
      toast.error("Error syncing to current time position");
    }
  };

  useEffect(() => {
    const fetchSubtitles = async () => {
      try {
        setIsLoadingSubtitles(true);
        setSubtitleError(null);

        const response = await axios.post(GET_SRT, {
          projectId: projectFile.id,
          language: languageCode,
          startTime: 0,
          endTime: 100000 // Use a large value to get all subtitles
        });

        if (response.data && response.data.subtitles) {
          setSubtitles(response.data.subtitles);
        } else {
          // For backward compatibility if the API returns in the old format
          setSubtitles([]);
          console.warn("Subtitle data format not as expected");
        }
      } catch (err) {
        console.error("Error fetching subtitles:", err);
        setSubtitleError(err.message);
        toast.error("Failed to load subtitles");
      } finally {
        setIsLoadingSubtitles(false);
      }
    };

    if (projectFile.id && languageCode) {
      fetchSubtitles();
    }
  }, [projectFile.id, languageCode]);

  return (
    <Grid
      item
      container
      justifyContent="center"
      marginTop={3}
      style={{ fontFamily: "var(--global-text-font)" }}
    >
      {/* {console.log(audioData)} */}
      <Loader Load={audioLoader} />
      <Loader Load={loader} />
      <ToastContainer position="top-center" theme="dark" />
      <Grid item container xs={11.5} direction="column">
        <Grid item className={classes["header"]}>
          Quality Assurance
        </Grid>
        <Grid item container direction="row">
          <Grid item container xs={12} lg={5.85} direction="column" marginRight={2}>
            <Grid
              item
              container
              className={
                true ? classes["OnAudioFiles"] : classes["OffAudioFiles"]
              }
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes["AudioTitles"]}
                justifyContent="space-between"
              >
                <Grid item container marginLeft={1.5} xs={3} direction="row">
                  <Grid item marginRight={1}>
                    <AutoStoriesIcon fontSize="medium" />
                  </Grid>
                  <Grid item className={classes["AudioTitleFont"]}>
                    Version names
                  </Grid>
                </Grid>

                <Grid
                  item
                  fontSize="40px"
                  onClick={() => {
                    // setOpenCharactersFolder(!openCharactersFolder);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  {true ? (
                    <KeyboardArrowUpIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="large" />
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                className={classes["AudioFilesBoxScroller"]}
                xs={8}
              >
                <Grid
                  item
                  container
                  direction="row"
                  gap={3}
                  marginTop={1}
                  marginLeft={1}
                >
                  {dubbedVideosList?.map((video, index) => (
                    <Grid
                      key={index}
                      container
                      item
                      xs={11.8}
                      alignItems="center"
                      justifyContent="space-evenly"
                      height="40px"
                      bgcolor={
                        videoVersion === video.version
                          ? "red"
                          : `${secondaryColor}`
                      }
                      onClick={() => {
                        setVidoSource(video.url);
                        fetchQaulityAssuranceVersion(video.uniqueId);
                        setVideoVersion(video.version);
                        setVideoUniqueId(video.uniqueId);
                      }}
                    >
                      {/* <Grid item xs={0.1} color="#F5F5F5">
                        <SquareIcon />
                      </Grid> */}
                      <Grid item color="#FFFFFF" fontSize="17px">
                        {video.version}
                      </Grid>
                      <Grid item>
                        {" "}
                        <CopyLinkButton link={video.url} />
                      </Grid>
                      <Grid item color="#FFFFFF" fontSize="17px">
                        {convertToDate(video.creationDate)}
                      </Grid>

                      {/* <Grid
                        item
                        xs={2}
                        // onClick={() => removeCharacter(char.speaker)}
                        sx={{ cursor: "pointer" }}
                      >
                        <DeleteIcon />
                      </Grid> */}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={classes["AudioWorkPlace"]}
              marginTop={1}
              direction="column"
            >
              <Grid
                item
                container
                direction="row"
                // justifyContent="space-between"
                alignItems="center"
                className={classes["AudioTitles"]}
              >
                <Grid
                  item
                  marginRight={38}
                  marginLeft={4.5}
                  className={classes["AudioTitleFont"]}
                >
                  Video Version : {" " + videoVersion}
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item container direction="row" alignItems="center">
                  <Grid
                    item
                    marginRight={32}
                    marginLeft={5}
                    marginTop={2}
                    marginBottom={2}
                    className={classes["AudioWorkPlaceInsideTitle"]}
                  >
                    {/* {videoVersion} */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  className={classes["AudioWorkPlaceChatBoxScroller"]}
                  maxHeight={{
                    xl: "800px",
                    lg: "360px",
                    md: "800px",
                    xs: "360px",
                  }}
                  ref={containerRef}
                >
                  {audioData ? (
                    <>
                      {audioData.map((data, index) => (
                        <React.Fragment key={index}>
                          <Grid
                            item
                            container
                            direction="row"
                            display="flex"
                            gap={1}
                            // justifyContent="space-between"
                            marginBottom={0.5}
                          >
                            <Grid
                              item
                              container
                              direction="column"
                              xs={1.8}
                              marginLeft={0.2}
                              gap={0.5}
                              alignItems="center"
                              className={classes["AudioWorkPlaceTimeline"]}
                            >
                              <Grid item>{findDisplayName(data.speaker)}</Grid>
                              <Grid item>
                                <textarea
                                  className={
                                    classes["AudioWorkPlaceTimelineEdit"]
                                  }
                                  value={formatTime(data.start)}
                                  type="text"
                                  onChange={(e) =>
                                    handleStartTimeChange(e, data.id)
                                  }
                                ></textarea>
                              </Grid>
                              <Grid item>
                                <textarea
                                  className={
                                    classes["AudioWorkPlaceTimelineEdit"]
                                  }
                                  value={formatTime(data.end)}
                                  type="text"
                                  onChange={(e) =>
                                    handleEndTimeChange(e, data.id)
                                  }
                                ></textarea>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              container
                              className={classes["AudioWorkPlaceBoxes"]}
                              direction="row"
                              paddingLeft={2}
                              paddingRight={2}
                              paddingTop={1}
                              justifyContent="space-between"
                              xs={9.9}
                              onClick={() => {
                                setSeekTime(data.start);
                                setSeekTimeIndex(seekTimeIndex + 1);
                              }}
                              sx={{ cursor: "pointer" }}
                            >
                              <Grid xs={4} item container direction="column">
                                <Grid item marginTop={2.5}>
                                  <Grid item fontSize={13} color="green">
                                    Duration:{" "}
                                    {(data.end - data.start).toFixed(3)}
                                  </Grid>
                                  <ReactAudioPlayer
                                    style={{
                                      width: "80%",
                                      height: "20px",
                                    }}
                                    src={data.originalAudio}
                                    controls
                                  />
                                </Grid>

                                <Grid item>
                                  <textarea
                                    className={
                                      classes["AudioWorkTextEditInput"]
                                    }
                                    style={{
                                      color:
                                        currentTime >= data.start &&
                                          currentTime < data.end
                                          ? "red"
                                          : "",
                                    }}
                                    value={data.text}
                                    type="text"
                                    onChange={(e) =>
                                      handleTextChange(e, data.id)
                                    }
                                  ></textarea>
                                </Grid>
                              </Grid>
                              <Grid xs={5.5} item container direction="row">
                                {data.keepOriginalText ? (
                                  <></>
                                ) : (
                                  <>
                                    <Grid
                                      item
                                      container
                                      direction="column"
                                    // alignContent="center"
                                    >
                                      <Grid>
                                        <Grid
                                          item
                                          fontSize={13}
                                          color="green"
                                          marginLeft={3.5}
                                        >
                                          <Grid item>
                                            {checkTranslationLength(
                                              data.end - data.start,
                                              findTransObjectsbyLangCode(
                                                data?.translations
                                              )?.translatedAudioDuration
                                                ? findTransObjectsbyLangCode(
                                                  data?.translations
                                                )?.translatedAudioDuration
                                                : data?.translatedAudioDurationTemp
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          item
                                          fontSize={13}
                                          color="green"
                                          container
                                          direction="row"
                                          gap={1}
                                        >
                                          <Grid item>
                                            <select
                                              className={
                                                classes["AudioVolumeSelector"]
                                              }
                                              value={
                                                findTransObjectsbyLangCode(
                                                  data?.translations
                                                )?.translatedAudioVolume || ""
                                              }
                                              onChange={(e) => {
                                                handleTranslatedAudioVolume(
                                                  e,
                                                  data.id
                                                );
                                              }}
                                            >
                                              <option value="">
                                                select added volume
                                              </option>
                                              <option value={null}>
                                                Automatic Volume
                                              </option>
                                              <option value={0}>
                                                Raw Volume
                                              </option>
                                              {Array.from(
                                                { length: 21 },
                                                (_, i) => i - 10
                                              ).map((volume) => (
                                                <option
                                                  key={volume}
                                                  value={volume}
                                                >
                                                  {volume} dB
                                                </option>
                                              ))}
                                            </select>
                                          </Grid>
                                          <Grid item>
                                            {"Duration: "}
                                            {findTransObjectsbyLangCode(
                                              data.translations
                                            ).translatedAudio
                                              ? findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudioDuration !==
                                                undefined
                                                ? findTransObjectsbyLangCode(
                                                  data.translations
                                                ).translatedAudioDuration
                                                : data.translatedAudioDurationTemp
                                              : "no audio"}
                                            {handleVoiceOverLapping(
                                              data.id,
                                              findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudio
                                                ? findTransObjectsbyLangCode(
                                                  data.translations
                                                ).translatedAudioDuration
                                                : null
                                            )}
                                            {" | "}
                                            {findTransObjectsbyLangCode(
                                              data.translations
                                            ).translatedAudioVolume === null ||
                                              findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudioVolume ===
                                              undefined
                                              ? "Volume: original"
                                              : "Volume: " +
                                              findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudioVolume +
                                              " dB"}
                                          </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                          <GlobalAudioPlayer
                                            ref={(el) =>
                                              (audioRefs.current[index] = el)
                                            }
                                            src={
                                              findTransObjectsbyLangCode(
                                                data.translations
                                              )?.translatedAudio
                                            }
                                            decible={
                                              findTransObjectsbyLangCode(
                                                data.translations
                                              ).translatedAudioVolume
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                      <Grid item marginTop={0.6}>
                                        <textarea
                                          className={
                                            classes["AudioWorkTextEditInput"]
                                          }
                                          style={{
                                            color:
                                              currentTime >= data.start &&
                                                currentTime < data.end
                                                ? "red"
                                                : "",
                                          }}
                                          value={
                                            findTransObjectsbyLangCode(
                                              data.translations
                                            ).translatedText
                                          }
                                          type="text"
                                          onChange={(e) =>
                                            handleTranslationChange(e, data.id)
                                          }
                                        ></textarea>
                                      </Grid>
                                    </Grid>
                                  </>
                                )}
                              </Grid>

                              <Grid
                                xs={2.5}
                                item
                                container
                                direction="column"
                                justifyContent="center"
                              >
                                <Grid item>
                                  <textarea
                                    className={
                                      classes["AudioWorkNoteEditInput"]
                                    }
                                    value={
                                      findTransObjectsbyLangCode(
                                        data.translations
                                      ).translatedAudioNote
                                        ? findTransObjectsbyLangCode(
                                          data.translations
                                        ).translatedAudioNote
                                        : ""
                                    }
                                    placeholder="add note here"
                                    type="text"
                                    onChange={(e) =>
                                      handleNoteChange(e, data.id)
                                    }
                                  ></textarea>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              marginTop={2}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Grid item>
                  <TaskButton
                    onClick={() => nextPrevPageAPI("previous")}
                    width="80px"
                    disabled={Number(pageNumber) === 1 || pageButtonActivity}
                  >
                    Previous
                  </TaskButton>
                </Grid>

                <Grid item>
                  <span>
                    Page {pageNumber} of {totalPages}
                  </span>
                </Grid>

                <Grid item>
                  <TaskButton
                    width="80px"
                    onClick={() => nextPrevPageAPI("next")}
                    disabled={
                      Number(pageNumber) === totalPages || pageButtonActivity
                    }
                  >
                    Next
                  </TaskButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              gap={4}
              marginTop={1}
              marginLeft={8}
            >
              <Grid item>
                <input
                  type="text"
                  style={{ width: "40px" }}
                  value={goToPageNumber}
                  // placeholder="Enter the page number "
                  onChange={(e) => {
                    setGoToPageNumber(e.target.value >= 1 ? e.target.value : 1);
                  }}
                />
              </Grid>
              <Grid item>
                <TaskButton
                  onClick={() => goToPageAPI(goToPageNumber)}
                  width="80px"
                >
                  Go
                </TaskButton>
              </Grid>
            </Grid>
          </Grid>

          {/* <QAVideoSlider
            source={videoSource}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
            goToPageAccordingTimeAPI={goToPageAccordingTimeAPI}
          /> */}

          <UnifiedVideoPlayer
            source={videoSource}
            seekTime={seekTime}
            displayAudioTime={displayAudioTime}
            seekTimeIndex={seekTimeIndex}
            setPausePlay={setPausePlay}
            layout="vertical"
            dualAudio={false}
            showDebug={false}
            customClasses={{
              videoContainer: classes["AudioOutPutVideo"],
              controlsContainer: classes["AudioOutPutController"],
              timeDisplay: classes["AudioOutPutShownTime"],
              selector: classes["AudioOutPutShowTimeOptionBox"]
            }}
            onTimeSync={handleTimeSync}
            showSubtitles={true} // Enable subtitle display
            subtitles={subtitles} // Pass the subtitles data
          />

          <Grid
            item
            container
            xs={12}
            direction="row"
            alignContent="center"
            justifyContent="flex-end"
            marginTop={2}
          // gap={2}
          >
            {/* <Grid item marginRight={2}>
              <button
                className={classes["AudioWorkPlaceButton"]}
                onClick={saveData}
              >
                Save
              </button>
            </Grid> */}

            {/* <Grid item>
              <button
                className={classes["AudioWorkPlaceButton"]}
                onClick={submitData}
              >
                Final Submission
              </button>
            </Grid> */}
            {/* <Grid item marginLeft={2}>
              {subTranscribe.length > 0 ? (
                <>
                  <CheckIcon />
                </>
              ) : (
                <></>
              )}
            </Grid> */}

            <Grid item>
              <TaskButton
                onClick={handleDownloadSRT}
                disabled={srtDownloadLoading}
                width="200px"
              >
                {srtDownloadLoading ? 'Generating SRT...' : 'Download SRT'}
              </TaskButton>
            </Grid>
            {srtDownloadError && (
              <Grid item>
                <span style={{ color: 'red', marginLeft: '10px' }}>
                  Error downloading SRT
                </span>
              </Grid>
            )}

            {isLoadingSubtitles && (
              <Grid item>
                <Typography variant="caption" color="textSecondary">
                  Loading subtitles...
                </Typography>
              </Grid>
            )}

            {subtitleError && (
              <Grid item>
                <Typography variant="caption" color="error">
                  Subtitles could not be loaded: {subtitleError}
                </Typography>
              </Grid>
            )}

          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QAScreen;