// DemoConstants.js
import imageFemale1 from "../../Assets/Images/Demos/female-1.png";
import imageFemale2 from "../../Assets/Images/Demos/female-2.png";
import imageMale1 from "../../Assets/Images/Demos/male-1.png";

// Character data for voice selection
// export const CHARACTERS = [
//     {
//         id: "Amara_en_2025_03_13",
//         name: "Amara",
//         image: imageFemale1,
//         provider: "custom"
//     },
//     {
//         id: "Sophia-en-2025-03-18",
//         name: "Sophia",
//         image: imageFemale2,
//         provider: "custom"
//     },
//     {
//         id: "Natalie",
//         name: "Natalie",
//         image: imageFemale2,
//         provider: "custom"
//     },
//     {
//         id: "Gabriel",
//         name: "Gabriel",
//         image: imageMale1,
//         provider: "custom"
//     },
//     {
//         id: "Marcus",
//         name: "Marcus",
//         image: imageMale1,
//         provider: "custom"
//     },
//     {
//         id: "Ethan",
//         name: "Ethan",
//         image: imageMale1,
//         provider: "custom"
//     }
// ];

export const CHARACTERS = [
        {
            id: "Sophia-en-2025-03-18",
            name: "Sophia",
            image: imageFemale2,
            provider: "custom"
        },
        {
            id: "Amara-en-2025-03-13",
            name: "Amara",
            image: imageFemale1,
            provider: "custom"
        },
        {
            id: "Gabriel-en-2025-03-17",
            name: "Gabriel",
            image: imageMale1,
            provider: "custom"
        },
        {
            id: "Marcus-en-2025-03-17",
            name: "Marcus",
            image: imageMale1,
            provider: "custom"
        },
        {
            id: "Natalie-en-2025-03-17",
            name: "Natalie",
            image: imageFemale2,
            provider: "custom"
        },
        {
            id: "Ethan-en-2025-03-17",
            name: "Ethan",
            image: imageMale1,
            provider: "custom"
        }
    ]


// Emotions with positions for the EmotionWheel component
export const EXPANDED_EMOTIONS = [
    // Top half (Aroused)
    { id: "Fear", name: "Fear", position: { x: 75, y: 95 } },
    { id: "Surprise-Delight", name: "Surprise/Delight", position: { x: 170, y: 95 } },
    { id: "Anger", name: "Anger", position: { x: 45, y: 135 } },
    { id: "Shock", name: "Shock", position: { x: 120, y: 135 } },
    { id: "Excitement-Enthusiasm", name: "Excitement", position: { x: 180, y: 135 } },
    { id: "Passion", name: "Passion", position: { x: 225, y: 135 } },
    { id: "Grief", name: "Grief", position: { x: 75, y: 175 } },
    { id: "Joy-Celebration", name: "Joy/Celebration", position: { x: 210, y: 175 } },

    // Middle section
    { id: "Cold-Anger-Sarcasm", name: "Cold/Anger/Sarcasm", position: { x: 60, y: 225 } },
    { id: "Indifference", name: "Indifference", position: { x: 120, y: 225 } },
    { id: "Contentment", name: "Contentment", position: { x: 180, y: 225 } },
    { id: "Fondness", name: "Fondness", position: { x: 225, y: 225 } },

    // Bottom half (Unaroused)
    { id: "Sadness", name: "Sadness", position: { x: 75, y: 275 } },
    { id: "Depression-Despair", name: "Despair", position: { x: 120, y: 275 } },
    { id: "Sleepiness", name: "Sleepiness", position: { x: 180, y: 275 } },
    { id: "Serenity", name: "Serenity", position: { x: 210, y: 275 } },

    // Default neutral state
    // { id: "Neutral", name: "Neutral", position: { x: 150, y: 190 } }
];

// Wheel configuration settings
export const WHEEL_CONFIG = {
    centerX: 150,
    centerY: 190,
    radius: 120
};