// AudiobookPerformanceDirection.jsx
import React, { useEffect, useState, useMemo, useRef, useContext } from 'react';
import { Grid, Typography, Fab, Zoom, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Store } from "../../Store";
import { GET_AUDIOBOOK_DIALOGUES, MERGING_AUDIOBOOK, REGENERATE_ALL_VOICES, GET_AUDIOBOOK_DETAILS, UPDATE_AUDIOBOOK_VOICE } from '../../MProjectConst';
import { useNavigate } from 'react-router-dom'; // Add this import
import Loader from '../../Components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import PopoverControls from '../../Components/AudioTools/PopoverControls';
import AudiobookPlayer from '../../Components/AudioTools/AudiobookPlayer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';

const AudiobookPerformanceDirection = () => {
    const navigate = useNavigate(); // Add this
    const params = useParams();
    const { projectId } = params;
    const { state, dispatch: ctxDispatch } = useContext(Store);
    const [dialogues, setDialogues] = useState([]);
    const [dataLoader, setDataLoader] = useState(false);
    const [selectedDialogueId, setSelectedDialogueId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [audioUrl, setAudioUrl] = useState('');
    const [playingIndex, setPlayingIndex] = useState(null); // Track the playing dialogue
    const [selectedIndex, setSelectedIndex] = useState(null); // New state to track selected index
    const [currentIndex, setCurrentIndex] = useState(0);
    const [adjacentDialogueId, setAdjacentDialogueId] = useState(null);
    const [selectedDialogue, setSelectedDialogue] = useState(null);
    const [paragraphInfo, setParagraphInfo] = useState(null);
    const { projectFile, userInfo } = state;
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [showNumbers, setShowNumbers] = useState(false);
    const [versionName, setVersionName] = useState('');
    const [isMerging, setIsMerging] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState('');
    const [availableVoices, setAvailableVoices] = useState({});
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [newVoiceId, setNewVoiceId] = useState('');
    const [isRegeneratingVoices, setIsRegeneratingVoices] = useState(false);

    // Ref to control AudiobookPlayer
    const audiobookPlayerRef = useRef(null);
    const mainPlayerStateRef = useRef({
        isPlaying: false,
        wasPlaying: false // Add this to track state before sentence play
    });

    useEffect(() => {
        if (selectedDialogueId) {
            const updatedDialogue = dialogues.find(d => d.id === selectedDialogueId);
            setSelectedDialogue(updatedDialogue);
        }
    }, [dialogues, selectedDialogueId]);


    useEffect(() => {
        const fetchVoiceInfo = async () => {
            if (projectId) {
                try {
                    const response = await axios.get(`${GET_AUDIOBOOK_DETAILS}/${projectId}`);
                    if (response.data.success) {
                        const project = response.data.project;
                        if (project.character && project.character.uid) {
                            // If the voice has a provider prefix, keep it; otherwise, use the UID as is
                            if (project.character.provider === 'custom') {
                                setSelectedVoice(`custom:${project.character.uid}`);
                            } else {
                                setSelectedVoice(project.character.uid);
                            }
                        }
                    }
                } catch (err) {
                    console.error("Error fetching project voice information:", err);
                }
            }
        };

        fetchVoiceInfo();

        // Initialize available voices - both ElevenLabs and Custom Model voices
        setAvailableVoices({
            // ElevenLabs voices
            'TJdGgFoRwIpDGaLZCRzq': 'Marcus (M)',
            'MWzURibm2jQVREQlGp7y': 'Alex (M)',
            'mUVoHLBTFzjOr0eQsoFX': 'Neal (M)',
            'EiNlNiXeDU1pqqOPrYMO': 'John (M)',
            'uju3wxzG5OhpWcoi3SMy': 'Michael (M)',
            'NFG5qt843uXKj4pFvR7C': 'Adam (M)',
            'GqSKXqyx5UloX8k3fdM7': 'Bria (F)',
            'lxYfHSkYm1EzQzGhdbfc': 'Jessica (F)',
            'ZqTgRkmbVg1mWhJwYVEe': 'Maya (F)',
            'i4CzbCVWoqvD0P1QJCUL': 'Ivy (F)',
            '5l5f8iK3YPeGga21rQIX': 'Adeline (F)',
            'rCmVtv8cYU60uhlsOo1M': 'Ana (F)',
            'NvvkRbxCrZ6F7iA9pBY5': 'Sara (F)',
            // Custom Model voices
            'custom:Male_audiobook_1': 'James (M)(Custom)',
            'custom:Male_audiobook_2': 'Robert (M)(Custom)',
            'custom:Male_audiobook_3': 'William (M)(Custom)',
            'custom:Female_audiobook_1': 'Emma (F)(Custom)',
            'custom:Female_audiobook_2': 'Sophia (F)(Custom)',
            'custom:Female_audiobook_3': 'Olivia (F)(Custom)',
            // Special voices
            'NvvkRbxCrZ6F7iA9pBY5': 'Sara (F)(Special)',
        });
    }, [projectId]);

    useEffect(() => {
        // Check for authentication
        if (!userInfo) {
            const savedUserInfo = localStorage.getItem("userInfo");
            if (savedUserInfo) {
                // Restore user info from localStorage
                ctxDispatch({
                    type: "USER_SIGNIN",
                    payload: JSON.parse(savedUserInfo)
                });
            } else {
                // No auth found, redirect to login
                navigate('/login', {
                    state: {
                        from: `/audiobooks/performancedirection/${projectId}`
                    }
                });
            }
        }
    }, [userInfo, projectId, navigate, ctxDispatch]);

    useEffect(() => {
        const fetchData = async () => {
            setDataLoader(true);
            try {
                // Add auth header to request
                const config = {
                    headers: {
                        Authorization: `Bearer ${userInfo?.token}`,
                    },
                };
                const response = await axios.get(
                    `${GET_AUDIOBOOK_DIALOGUES}/${projectId}`,
                    config
                );
                if (response.data.success) {
                    setDialogues(response.data.dialogues || []);
                }
            } catch (err) {
                console.error(err);
                // Handle unauthorized error
                if (err.response?.status === 401) {
                    navigate('/login');
                }
            }
            setDataLoader(false);
        };

        if (userInfo) { // Only fetch if we have user info
            fetchData();
        }
    }, [projectId, userInfo, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            setDataLoader(true);
            try {
                const response = await axios.get(`${GET_AUDIOBOOK_DIALOGUES}/${projectId}`);
                if (response.data.success) {
                    setDialogues(response.data.dialogues || []);
                }
            } catch (err) {
                console.error(err);
            }
            setDataLoader(false);
        };
        fetchData();
    }, [projectId]);

    useEffect(() => {
        if (setPlayingIndex) {
            setPlayingIndex(currentIndex); // Update the playingIndex in the parent component
        }
    }, [currentIndex, setPlayingIndex]);

    const sortedDialogues = useMemo(() => {
        return [...dialogues].sort((a, b) => a.sequence - b.sequence);
    }, [dialogues]);

    const groupedDialogues = useMemo(() => {
        return sortedDialogues.reduce((acc, dialogue) => {
            const { paragraph_id } = dialogue;
            if (!acc[paragraph_id]) {
                acc[paragraph_id] = [];
            }
            acc[paragraph_id].push(dialogue);
            return acc;
        }, {});
    }, [sortedDialogues]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollThreshold = 300; // Adjust this value to change when the button appears
            setShowScrollTop(window.pageYOffset > scrollThreshold);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Handle word click and track the selected index
    const handleWordClick = (event, dialogue, index) => {
        if (selectedDialogueId === dialogue.id) {
            setSelectedDialogueId(null);
            setAnchorEl(null);
            setAudioUrl('');
            setSelectedIndex(null);
            setAdjacentDialogueId(null);
        } else {
            const leftDialogue = sortedDialogues[index - 1] || null;
            const rightDialogue = sortedDialogues[index + 1] || null;

            // Get paragraph information
            const paragraphInfo = {
                currentParagraphId: dialogue.paragraph_id,
                leftParagraphId: leftDialogue?.paragraph_id,
                rightParagraphId: rightDialogue?.paragraph_id
            };

            setSelectedDialogueId(dialogue.id);
            setAnchorEl(event.currentTarget);
            setAudioUrl(dialogue.audio_url || '');
            setSelectedIndex(index);
            setAdjacentDialogueId({
                left: leftDialogue?.id || null,
                right: rightDialogue?.id || null,
                audioDuration: dialogue.audio_duration
            });
            setSelectedDialogue(dialogue);
            setParagraphInfo(paragraphInfo); // Add a new state for paragraph info
        }
    };

    // Function to handle "Play From Here" action
    const handlePlayFromHere = () => {
        if (selectedIndex !== null && audiobookPlayerRef.current) {
            audiobookPlayerRef.current.playFromIndex(selectedIndex);
            handleClosePopover();
        }
    };

    const handleClosePopover = () => {
        setSelectedDialogueId(null);
        setAnchorEl(null);
        setAudioUrl('');
        setSelectedIndex(null);
    };

    const handleVersionChange = (version) => {
        if (!selectedDialogue?.audio_versions?.[version]) {
            console.error('Version data not available');
            return;
        }

        setDialogues(prev => prev.map(d =>
            d.id === selectedDialogue.id
                ? {
                    ...d,
                    audio_url: selectedDialogue.audio_versions[version].url || d.audio_url,
                    audio_duration: selectedDialogue.audio_versions[version].duration || d.audio_duration,
                    currentVersion: version
                }
                : d
        ));
    };

    const handleAudioGenerated = (dialogueId, versionData) => {
        setDialogues(prevDialogues => prevDialogues.map(dialogue => {
            if (dialogue.id === dialogueId) {
                return {
                    ...dialogue,
                    audio_versions: {
                        ...dialogue.audio_versions,
                        [versionData.version]: {
                            url: versionData.url,
                            duration: versionData.duration,
                            created_at: versionData.created_at
                        }
                    }
                };
            }
            return dialogue;
        }));
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleMergeAudio = async () => {
        if (!versionName.trim()) {
            toast.error('Please enter a version name');
            return;
        }

        if (!userInfo) {
            toast.error('Please log in to merge audio');
            return;
        }

        setIsMerging(true);
        try {
            const response = await axios.post(
                MERGING_AUDIOBOOK,
                {
                    projectId,
                    projectName: projectFile.name,
                    langCode: 'en',
                    version: versionName,
                    emails: [userInfo.email, 'ak@y76.io', 'mohammad@y76.io', 'bg@y76.io'],
                    submitterName: userInfo.name
                },
                {
                    headers: {
                        Authorization: `Bearer ${userInfo.token}`,
                    },
                }
            );

            if (response.data.success) {
                toast.success('Merge process started successfully');
            } else {
                toast.error(response.data.message || 'Failed to start merge process');
            }
        } catch (error) {
            console.error('Error merging audio:', error);
            // Handle unauthorized error
            if (error.response?.status === 401) {
                navigate('/login');
            } else {
                toast.error(error.response?.data?.message || 'Failed to merge audio');
            }
        } finally {
            setIsMerging(false);
        }
    };

    const handleVoiceChange = (event) => {
        const newVoice = event.target.value;
        if (newVoice !== selectedVoice) {
            setNewVoiceId(newVoice);
            setOpenConfirmDialog(true);
        }
    };

    const handleCloseDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirmVoiceChange = async (regenerateAll) => {
        setOpenConfirmDialog(false);

        // Update the selected voice
        setSelectedVoice(newVoiceId);

        // Parse the voice ID to check if it's a custom voice
        const isCustomVoice = newVoiceId.startsWith('custom:');
        let voiceId = newVoiceId;
        let voiceProvider = 'elevenlabs'; // Default provider
        let voiceName = availableVoices[newVoiceId].split(' ')[0]; // Extract just the name part

        // If it's a custom voice, extract the actual voice ID and set provider
        if (isCustomVoice) {
            voiceId = newVoiceId.split(':')[1];
            voiceProvider = 'custom';
        }

        console.log('Voice change details:', {
            selectedVoice: newVoiceId,
            extractedVoiceId: voiceId,
            voiceProvider,
            voiceName
        });

        // Update the voice in the database
        try {
            await axios.post(UPDATE_AUDIOBOOK_VOICE, {
                projectId,
                voiceId: voiceId,
                voiceProvider: voiceProvider,
                voiceName: voiceName
            });

            // If user chose to regenerate all dialogues
            if (regenerateAll) {
                setIsRegeneratingVoices(true);
                try {
                    await axios.post(REGENERATE_ALL_VOICES, {
                        projectId,
                        voiceId: voiceId,
                        voiceProvider: voiceProvider,
                        voiceName: voiceName,
                        emotion: "neutral" // Default emotion
                    });
                    toast.success("All dialogues are being regenerated with the new voice. This may take some time.");
                } catch (error) {
                    console.error("Error regenerating voices:", error);
                    toast.error("Failed to regenerate voices. Please try again.");
                } finally {
                    setIsRegeneratingVoices(false);
                }
            } else {
                toast.success(`Voice changed to ${availableVoices[newVoiceId]}. Use the +1 or +3 buttons to regenerate dialogues as needed.`);
            }
        } catch (error) {
            console.error("Error updating voice:", error);
            toast.error("Failed to update voice. Please try again.");
            // Revert selection on error
            setSelectedVoice(selectedVoice);
        }
    };

    return (
        <Grid container spacing={3} className="w-full">
            <Loader Load={dataLoader} />
            <ToastContainer position="top-center" theme="dark" />

            {/* AudiobookPlayer Section */}
            <Grid
                item
                xs={12}
                className="flex justify-center"
                sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                    paddingBottom: 2,
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}
            >
                <div className="w-full max-w-4xl">
                    <AudiobookPlayer
                        ref={audiobookPlayerRef}
                        dialogues={sortedDialogues}
                        setPlayingIndex={setPlayingIndex}
                        stateRef={mainPlayerStateRef}
                        showNumbers={showNumbers}
                        onNumberingChange={setShowNumbers}
                    />
                </div>
            </Grid>

            {/* Add voice selector before the version name field */}
            <Grid item>
                <Select
                    size="small"
                    value={selectedVoice}
                    onChange={handleVoiceChange}
                    displayEmpty
                    disabled={isRegeneratingVoices}
                    sx={{
                        minWidth: 180,
                        backgroundColor: 'white'
                    }}
                >
                    <MenuItem value="" disabled>Select Voice</MenuItem>


                    {/* ElevenLabs Voices Section */}
                    <MenuItem disabled style={{ fontWeight: 'bold' }}>ElevenLabs Voices</MenuItem>
                    {Object.entries(availableVoices)
                        .filter(([id]) => !id.startsWith('custom:') && id !== 'NvvkRbxCrZ6F7iA9pBY5')
                        .map(([id, name]) => (
                            <MenuItem key={id} value={id}>{name}</MenuItem>
                        ))
                    }

                    {/* Custom Model Voices Section */}
                    <MenuItem disabled style={{ fontWeight: 'bold', marginTop: '10px' }}>Custom Model Voices</MenuItem>
                    {Object.entries(availableVoices)
                        .filter(([id]) => id.startsWith('custom:'))
                        .map(([id, name]) => (
                            <MenuItem key={id} value={id}>{name}</MenuItem>
                        ))
                    }
                    {/* Special Voices Section */}
                    <MenuItem disabled style={{ fontWeight: 'bold', color: '#5c6bc0' }}>Special Voices</MenuItem>
                    <MenuItem value="NvvkRbxCrZ6F7iA9pBY5">Sara (F)(Special)</MenuItem>
                </Select>
            </Grid>

            {/* Text Area Section */}
            <Grid
                item
                xs={12}
                style={{
                    display: 'flex',
                    justifyContent: 'center', // Center horizontally
                }}
            >
                <div
                    style={{
                        width: '80%',
                        maxWidth: '100%', // Prevent horizontal overflow
                        minHeight: '500px',
                        padding: '16px',
                        fontSize: '16px',
                        lineHeight: '1.5',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        fontFamily: 'inherit',
                        overflowY: 'auto', // Enable vertical scrolling
                        overflowX: 'hidden', // Disable horizontal scrolling
                        boxSizing: 'border-box',
                    }}
                >
                    {Object.keys(groupedDialogues).map((paragraphId) => (
                        <Typography
                            key={paragraphId}
                            paragraph
                            style={{
                                wordWrap: 'break-word', // Ensure long words break
                            }}
                        >
                            {groupedDialogues[paragraphId].map((dialogue, dialogueIndex) => {
                                const globalIndex = sortedDialogues.findIndex(d => d.id === dialogue.id);
                                const words = dialogue.text.split(/(\s+)/);
                                const isPlaying = dialogue.sequence === playingIndex; // Check if the dialogue is currently playing
                                const isSelected = dialogue.id === selectedDialogueId; // Check if the dialogue is selected

                                return (
                                    <span
                                        key={`${dialogue.id}-${dialogueIndex}`}
                                        style={{
                                            backgroundColor: isPlaying ? '#ffeb3b' : isSelected ? '#d1e7dd' : 'transparent',
                                            padding: '2px',
                                            borderRadius: '3px',
                                            transition: 'background-color 0.3s',
                                            display: 'inline',
                                            marginBottom: '4px',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {showNumbers && (
                                            <Typography
                                                component="span"
                                                sx={{
                                                    fontSize: '0.75rem',
                                                    color: '#666',
                                                    marginRight: '2px',
                                                    userSelect: 'none',
                                                }}
                                            >
                                                {globalIndex + 1}:
                                            </Typography>
                                        )}
                                        {words.map((word, i) =>
                                            word.trim() !== '' ? (
                                                <span
                                                    key={i}
                                                    data-sequence={dialogue.sequence}
                                                    onClick={(e) => handleWordClick(e, dialogue, globalIndex)}
                                                    style={{ cursor: 'pointer', userSelect: 'none' }}
                                                    title="Click to view dialogue details"
                                                >
                                                    {word}
                                                </span>
                                            ) : (
                                                word
                                            )
                                        )}
                                    </span>
                                );
                            })}
                        </Typography>
                    ))}
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            position: 'fixed',
                            bottom: 20,
                            right: 20,
                            width: 'auto',
                            zIndex: 1000,
                            alignItems: 'center',
                            gap: 2,
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            padding: '10px',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                        }}
                    >
                        <Grid item>
                            <TextField
                                size="small"
                                label="Version Name"
                                value={versionName}
                                onChange={(e) => setVersionName(e.target.value)}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={handleMergeAudio}
                                disabled={isMerging}
                                sx={{ height: '40px' }}
                            >
                                {isMerging ? 'Merging...' : 'Merge Audio'}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <Zoom in={showScrollTop}>
                    <Fab
                        color="primary"
                        size="small"
                        onClick={scrollToTop}
                        sx={{
                            position: 'fixed',
                            bottom: 90,
                            right: 16,
                            zIndex: 1000,
                        }}
                        aria-label="scroll back to top"
                    >
                        <KeyboardArrowUpIcon />
                    </Fab>
                </Zoom>
            </Grid>

            <PopoverControls
                open={Boolean(selectedDialogueId)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                audioUrl={audioUrl}
                onPlayFromHere={handlePlayFromHere}
                selectedDialogueId={selectedDialogueId || ''}
                adjacentDialogueId={adjacentDialogueId || { left: null, right: null }}
                projectId={projectId || ''}
                audioDuration={adjacentDialogueId?.audioDuration || 0}
                dialogue={selectedDialogue}
                onVersionChange={handleVersionChange}
                onAudioGenerated={handleAudioGenerated}
                mainPlayerStateRef={mainPlayerStateRef}
                mainPlayerRef={audiobookPlayerRef}
                paragraphInfo={{
                    currentParagraphId: Number(selectedDialogue?.paragraph_id) || 0,
                    leftParagraphId: adjacentDialogueId?.left ? Number(dialogues.find(d => d.id === adjacentDialogueId.left)?.paragraph_id) || 0 : null,
                    rightParagraphId: adjacentDialogueId?.right ? Number(dialogues.find(d => d.id === adjacentDialogueId.right)?.paragraph_id) || 0 : null
                }}
            />

            <Dialog
                open={openConfirmDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change Voice"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You're changing the voice to {newVoiceId ? availableVoices[newVoiceId] : ''}.
                        Would you like to regenerate all dialogues with this new voice now?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmVoiceChange(false)} color="primary">
                        No
                    </Button>
                    <Button onClick={() => handleConfirmVoiceChange(true)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add a loading indicator for regeneration */}
            {isRegeneratingVoices && <Loader Load={isRegeneratingVoices} />}
        </Grid>
    );
};

export default AudiobookPerformanceDirection;
