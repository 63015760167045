import {
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Store } from "../Store.js";
import { GET_PROJECTS_FOR_SINGLE_USER } from "../MProjectConst.js";
import {
  convertToDate,
  getLanguageName,
  getTaskName,
  transformRoles,
} from "../utils/generalFunctions.js";
import GlobalButton from "../Components/Buttons/GlobalButton.js";
import classes from "../Assets/Styles/Global.module.css";
import { secondaryColor } from "../utils/constants.js";

const LandingPage = () => {
  const navigate = useNavigate();
  const [myProjects, setMyProjects] = useState([]);
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const myProjects = await axios.post(
          GET_PROJECTS_FOR_SINGLE_USER,

          { id: userInfo.id }
        );
        setMyProjects(myProjects.data.projectsArray);
        const RolesByName = groupRolesByName(myProjects.data.projectsArray);

        // const organizedRoles = transformRoles(unOrganizedRoles);

        ctxDispatch({ type: "EMPLOYE_TASKS", payload: RolesByName });
        localStorage.setItem("employeTasks", JSON.stringify(RolesByName));
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [userInfo.id]);

  const handleWork = (project, role, language) => {
    ctxDispatch({ type: "PROJECT_FILE", payload: project });
    localStorage.setItem("projectFile", JSON.stringify(project));

    // Preserve user info in localStorage if not already there
    if (userInfo && !localStorage.getItem("userInfo")) {
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
    }

    if (project.type === 'audiobook') {
      if (role === "performancedirection") {
        // Make sure userInfo is included in the navigation state
        navigate(`/audiobooks/performancedirection/${project.id}/${language}`, {
          state: { userInfo: userInfo }
        });
      }
      return;
    }

    if (role === "transcriber") navigate("/work/transcriber");
    else if (role === "translator") navigate(`/work/translator/${language}`);
    else if (role === "performancedirection")
      navigate(`/work/performancedirection/${language}`);
    else if (role === "lipqualityassurance")
      navigate(`/work/lipqualityassurance/${language}`);
    else if (role === "speakerfaceidentification")
      navigate("/work/speakerfaceidentification");
    else if (role === "qualityassurance")
      navigate(`/work/qualityassurance/${language}`);
  };

  const groupRolesByName = (data) => {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.name]) {
        acc[item.name] = { name: item.name, roles: [] };
      }

      if (!acc[item.name].roles.includes(item.role)) {
        acc[item.name].roles.push(item.role);
      }

      return acc;
    }, {});

    return Object.values(groupedData);
  };

  useEffect(() => {
    ctxDispatch({ type: "PROJECT_FILE_CLEAR" });
    // localStorage.removeItem("projectFile");
  }, []);

  return (
    <Grid item container justifyContent="center" marginBottom="29.3%">
      {/* {console.log(myProjects)} */}
      {/* {console.log(userInfo)} */}
      <Grid item container xs={8.5} marginTop={7} direction="row">
        <Grid
          item
          marginBottom={5}
          xs={12}
          sx={{
            fontSize: "40px",
            fontWeight: "900",
          }}
          className={classes["header"]}
        >
          View my Assigned Projects
        </Grid>
        <Grid item xs={12}>
          <Table sx={{ minWidth: 650, bgcolor: "white" }}>
            <TableHead bgcolor="#5570dc">
              <TableRow>
                <TableCell>Project Name</TableCell>
                <TableCell>My Role</TableCell>
                <TableCell>View Original Content</TableCell>
                <TableCell>Original Language</TableCell>
                <TableCell>Final Languages</TableCell>
                <TableCell>Creation Date</TableCell>
                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myProjects.map((project, index) => (
                <TableRow key={index}>
                  <TableCell style={{ fontWeight: "600" }}>
                    {project.name}
                  </TableCell>
                  <TableCell>{getTaskName(project.role)}</TableCell>
                  <TableCell>
                    <a
                      style={{
                        textDecoration: "none",
                        color: `${secondaryColor}`,
                      }}
                      href={project.videoUrl}
                    >
                      Content
                    </a>
                  </TableCell>
                  <TableCell>{getLanguageName(project.orginalLang)}</TableCell>
                  <TableCell width="30%">
                    <Grid
                      item
                      container
                      direction="row"
                      gap={1}
                      alignItems="center"
                      justifyContent="center"
                    // bgcolor="red"
                    >
                      {project.finalLang?.map((language, index) => (
                        <Grid
                          xs={5.5}
                          item
                          container
                          key={index}
                          alignItems="center"
                          justifyContent="center"
                          width="300px"
                          height="32px"
                          sx={{
                            // background: `${secondaryColor}`,
                            background: "lightgrey",
                            fontWeight: "400",
                            borderRadius: "5px",
                          }}
                        >
                          <Grid item>{getLanguageName(language)}</Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                  <TableCell>{convertToDate(project.creationDate)}</TableCell>
                  <TableCell>
                    {project.role === "translator" ||
                      project.role === "qualityassurance" ||
                      project.role === "performancedirection" ||
                      project.role === "lipqualityassurance" ? (
                      <Select
                        color="primary"
                        defaultValue="Start Working"
                        size="small"
                      >
                        <MenuItem value="Start Working" disabled>
                          Start Working
                        </MenuItem>
                        {project.finalLang.map((language, index) => (
                          <MenuItem
                            key={index}
                            value={language}
                            onClick={() =>
                              handleWork(project, project.role, language)
                            }
                          >
                            {getLanguageName(language)}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <GlobalButton
                        color="primary"
                        onClick={() => {
                          handleWork(project, project.role);
                        }}
                        width="150px"
                      >
                        Start Working
                      </GlobalButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
