import React, { useRef, useState, useEffect } from "react";
import classes from "../../Assets/Styles/Demo.module.css";

const TextInputArea = ({
    inputText,
    setInputText,
    isLoading,
    onGenerateAudio,
    onInsertEmoji,
}) => {
    const textAreaRef = useRef(null);
    const [cursorPosition, setCursorPosition] = useState(0);

    const handleTextChange = (e) => {
        setInputText(e.target.value);
    };

    const handleSelectionChange = () => {
        if (textAreaRef.current) {
            setCursorPosition(textAreaRef.current.selectionStart);
        }
    };

    const insertEmojiAtCursor = (emoji) => {
        if (textAreaRef.current) {
            // Get current cursor position
            const currentPosition = cursorPosition;

            // Split text at cursor position
            const textBefore = inputText.substring(0, currentPosition);
            const textAfter = inputText.substring(currentPosition);

            // Create new text with emoji inserted
            const newText = textBefore + emoji + textAfter;

            // Update state
            setInputText(newText);

            // Calculate new cursor position (after the emoji)
            const newPosition = currentPosition + emoji.length;

            // Focus back to textarea and update cursor position after render
            setTimeout(() => {
                if (textAreaRef.current) {
                    textAreaRef.current.focus();
                    textAreaRef.current.setSelectionRange(newPosition, newPosition);
                    setCursorPosition(newPosition);
                }
            }, 0);
        }
    };

    return (
        <div className={classes["bubbleContainer"]}>
            <button
                className={classes["ttsButton"]}
                onClick={onGenerateAudio}
                disabled={isLoading}
            >
                {isLoading ? "⏳" : "🗣️"}
            </button>

            <button
                className={classes["breathButton"]}
                onClick={() => onInsertEmoji("💨")}
                disabled={isLoading}
            >
                💨
            </button>

            <button
                className={classes["laughButton"]}
                onClick={() => onInsertEmoji("😂")}
                disabled={isLoading}
            >
                😂
            </button>

            <textarea
                ref={textAreaRef}
                className={`${classes["bubbleInput"]} ${isLoading ? classes["bubbleInput-disabled"] : ""}`}
                value={inputText}
                onChange={handleTextChange}
                onSelect={handleSelectionChange}
                onKeyUp={handleSelectionChange}
                onMouseUp={handleSelectionChange}
                onClick={handleSelectionChange}
                placeholder="Type your text here..."
                readOnly={isLoading} // Make readonly when loading
            />
        </div>
    );
};

export default TextInputArea;