import React, { useRef, useState, useEffect, useMemo } from 'react';
import {
    Grid,
    Popover,
    Checkbox,
    FormControlLabel,
    Select,
    MenuItem,
    Badge,
    Button,
    Typography,
    Stack,
    Box,
    Tooltip,
    TextField,
    Alert
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ReactAudioPlayer from 'react-audio-player';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import axios from 'axios';
import Loader from '../Loader';
import { MERGE_DIALOGUES, REGENERATE_DIALOGUE_AUDIO, UPDATE_DIALOGUE_VERSION, SPLIT_DIALOGUE } from '../../MProjectConst'; // Import API constant
import SplitIcon from '@mui/icons-material/CallSplit';

const emotionVoiceIds = {
    neutral: 'dwo0oupDDyJeTBpyE6n3',
    happy: '6JwWE9DTC1jaWKrYtDv2',
    sad: 'v9cWqsqq8OHjQwKSB2FB',
    angry: 'lnyWAfuQnJNpm4YxQ864',
    afraid: 'aAvpc7Y595HXDxXuv9SV'
};

const emotionList = ['neutral', 'happy', 'sad', 'angry', 'afraid'];

const PopoverControls = ({
    open,
    anchorEl,
    onClose,
    audioUrl,
    onPlayFromHere,
    selectedDialogueId,
    adjacentDialogueId,
    projectId,
    audioDuration = 0,
    dialogue,
    onVersionChange,
    onAudioGenerated,
    mainPlayerStateRef,
    mainPlayerRef,
    paragraphInfo
}) => {
    const [loading, setLoading] = useState(false);
    const [mergeDirection, setMergeDirection] = useState(null);
    const audioPlayerRef = useRef(null);
    const [selectedEmotion, setSelectedEmotion] = useState('neutral');
    const [editedText, setEditedText] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(() =>
        dialogue?.currentVersion || "0"
    );
    const [audioVersions, setAudioVersions] = useState([]);

    const canMergeLeft = adjacentDialogueId?.left &&
        paragraphInfo?.currentParagraphId === paragraphInfo?.leftParagraphId;
    const canMergeRight = adjacentDialogueId?.right &&
        paragraphInfo?.currentParagraphId === paragraphInfo?.rightParagraphId;

    // const audioVersions = useMemo(() => {
    //     if (!dialogue?.audio_versions) return [];
    //     return Object.entries(dialogue.audio_versions)
    //         .filter(([_, data]) => data.url && data.duration)
    //         .map(([version, data]) => ({
    //             version,
    //             url: data.url,
    //             duration: data.duration || 0
    //         }));
    // }, [dialogue?.audio_versions]);

    const hasAudioVersions = audioVersions.length > 0;
    const currentVersion = dialogue?.audio_versions?.[selectedVersion]


    useEffect(() => {
        if (dialogue) {
            // Set initial text from current version or original text
            const versionText = currentVersion?.performance_text || dialogue.performance_text || dialogue.text;
            setEditedText(versionText || "");
            setHasUnsavedChanges(false);
        }
    }, [dialogue, selectedVersion]);

    useEffect(() => {
        if (dialogue?.audio_versions) {
            const versions = Object.entries(dialogue.audio_versions)
                .filter(([_, data]) => data.url && data.duration)
                .map(([version, data]) => ({
                    version,
                    url: data.url,
                    duration: data.duration || 0
                }))
                .sort((a, b) => parseFloat(a.version) - parseFloat(b.version));
            setAudioVersions(versions);
        }
    }, [dialogue?.audio_versions]);

    useEffect(() => {
        if (dialogue?.audio_versions) {
            // Get the actual current version from the dialogue
            const actualCurrentVersion = dialogue.currentVersion || "0";
            const versionData = dialogue.audio_versions[actualCurrentVersion];

            // Set the selected version in dropdown to match actual current version
            setSelectedVersion(actualCurrentVersion);

            // Set the text based on the current version's data
            if (versionData?.performance_text) {
                setEditedText(versionData.performance_text);
            } else {
                setEditedText(dialogue.text);
            }
            setHasUnsavedChanges(false);
        }
    }, [dialogue, open]);

    useEffect(() => {
        if (dialogue?.audio_versions && audioPlayerRef.current) {
            const versionData = dialogue.audio_versions[selectedVersion];
            if (versionData?.url) {
                const audio = audioPlayerRef.current.audioEl.current;
                if (audio) {
                    audio.src = versionData.url;
                    audio.load();
                }
            }
        }
    }, [selectedVersion, dialogue]);

    useEffect(() => {
        return () => {
            if (audioPlayerRef.current?.audioEl?.current) {
                audioPlayerRef.current.audioEl.current.onended = null;
            }
        };
    }, []);

    const handleMergeDialogues = async (direction) => {
        // Validate paragraph boundaries
        if (direction === 'left' && !canMergeLeft) {
            toast.error('Cannot merge with previous dialogue from different paragraph');
            setMergeDirection(null);
            return;
        }
        if (direction === 'right' && !canMergeRight) {
            toast.error('Cannot merge with next dialogue from different paragraph');
            setMergeDirection(null);
            return;
        }

        const mergeWithDialogueId =
            direction === 'left' ? adjacentDialogueId.left : adjacentDialogueId.right;

        if (!mergeWithDialogueId) {
            toast.error(`No dialogue to merge with on the ${direction}.`);
            setMergeDirection(null);
            return;
        }

        const confirm = window.confirm(
            `Are you sure you want to merge this dialogue with the ${direction} dialogue?`
        );
        if (!confirm) {
            setMergeDirection(null);
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post(MERGE_DIALOGUES, {
                projectId,
                dialogueId1: direction === 'left' ? mergeWithDialogueId : selectedDialogueId,
                dialogueId2: direction === 'left' ? selectedDialogueId : mergeWithDialogueId,
            });

            if (response.data.success) {
                toast.success("Dialogues merged successfully!");
                setMergeDirection(null);
                onClose();
                window.location.reload()
            } else {
                toast.error("Failed to merge dialogues.");
            }
        } catch (error) {
            console.error("Error merging dialogues:", error);
            toast.error("An error occurred while merging dialogues.");
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (direction) => {
        if (mergeDirection === direction) {
            setMergeDirection(null);
        } else {
            setMergeDirection(direction);
            handleMergeDialogues(direction);
        }
    };

    const handlePlaySentence = async () => {
        if (audioPlayerRef.current) {
            const audio = audioPlayerRef.current.audioEl.current;
            if (audio) {
                const wasPlaying = mainPlayerStateRef?.current?.isPlaying;

                if (wasPlaying && mainPlayerRef?.current?.pausePlayback) {
                    mainPlayerRef.current.pausePlayback();
                }

                audio.onended = () => {
                    if (wasPlaying && mainPlayerRef?.current?.resumePlayback) {
                        setTimeout(() => {
                            mainPlayerRef.current.resumePlayback();
                        }, 100);
                    }
                };

                try {
                    await audio.play();
                } catch (error) {
                    console.error("Error playing audio:", error);
                    // If there's an error, resume main player if it was playing
                    if (wasPlaying && mainPlayerRef?.current?.resumePlayback) {
                        mainPlayerRef.current.resumePlayback();
                    }
                }
            }
        }
    };

    const handleRegenerateAudio = async (count = 1) => {
        if (hasUnsavedChanges) {
            const confirmed = window.confirm(
                "You have unsaved text changes. Do you want to generate audio with these changes?"
            );
            if (!confirmed) return;
        }

        setLoading(true);
        try {
            const response = await axios.post(REGENERATE_DIALOGUE_AUDIO, {
                projectId,
                dialogueId: selectedDialogueId,
                count: count,
                emotion: selectedEmotion,
                performanceText: editedText
            });

            if (response.data.success) {
                const newVersions = response.data.versions
                    .filter(v => v && v.audio_url && v.audio_duration)
                    .map(v => ({
                        version: v.version.toString(),
                        url: v.audio_url,
                        duration: v.audio_duration,
                        performance_text: editedText
                    }));

                if (newVersions.length === 0) {
                    throw new Error('No valid versions received');
                }

                // Update parent component state if needed
                for (const version of newVersions) {
                    await onAudioGenerated?.(selectedDialogueId, {
                        version: version.version,
                        url: version.url,
                        duration: version.duration,
                        created_at: new Date(),
                        performance_text: version.performance_text
                    });
                }

                // Immediately update audioVersions state with new versions
                setAudioVersions(prev => {
                    const updatedVersions = [...prev];
                    newVersions.forEach(newVersion => {
                        const existingIndex = updatedVersions.findIndex(v => v.version === newVersion.version);
                        if (existingIndex >= 0) {
                            updatedVersions[existingIndex] = {
                                version: newVersion.version,
                                url: newVersion.url,
                                duration: newVersion.duration
                            };
                        } else {
                            updatedVersions.push({
                                version: newVersion.version,
                                url: newVersion.url,
                                duration: newVersion.duration
                            });
                        }
                    });
                    return updatedVersions.sort((a, b) => parseFloat(a.version) - parseFloat(b.version));
                });

                // If this is our first successful generation
                const wasFirstGeneration = !hasAudioVersions;
                if (wasFirstGeneration && newVersions.length > 0) {
                    const firstNewVersion = newVersions[0].version;
                    setSelectedVersion(firstNewVersion);
                    handleVersionChange(firstNewVersion);
                }

                setHasUnsavedChanges(false);
                toast.success(`Generated ${newVersions.length} new versions successfully!`);
            } else {
                throw new Error(response.data.message || 'Failed to generate new versions');
            }
        } catch (error) {
            console.error("Error generating new versions:", error);
            toast.error("An error occurred while generating new versions");
        } finally {
            setLoading(false);
        }
    };

    const handleVersionChange = async (version) => {
        if (hasUnsavedChanges) {
            const confirmed = window.confirm(
                "You have unsaved text changes. Are you sure you want to switch versions? Your changes will be lost."
            );
            if (!confirmed) return;
        }

        setLoading(true);
        try {
            const response = await axios.post(UPDATE_DIALOGUE_VERSION, {
                projectId,
                dialogueId: selectedDialogueId,
                version
            });

            if (response.data.success) {
                // Update dialogue data
                if (dialogue) {
                    dialogue.currentVersion = version;
                }

                // Update local state
                setSelectedVersion(version);
                const versionData = response.data.versionData;

                if (versionData) {
                    setEditedText(versionData.performance_text || dialogue.text);
                    setHasUnsavedChanges(false);

                    // Handle audio autoplay with proper event handling
                    if (audioPlayerRef.current) {
                        const audio = audioPlayerRef.current.audioEl.current;
                        if (audio) {
                            // Pause main player if playing
                            if (mainPlayerStateRef?.current?.isPlaying && mainPlayerRef?.current?.pausePlayback) {
                                mainPlayerRef.current.pausePlayback();
                            }

                            // Set up event listener for canplaythrough BEFORE changing src
                            const playAudioWhenReady = () => {
                                audio.play().catch(error => {
                                    console.error("Error auto-playing audio:", error);
                                });
                            };

                            // Remove any existing listeners to prevent duplicates
                            audio.removeEventListener('canplaythrough', playAudioWhenReady);

                            // Add listener that will fire once when ready to play
                            audio.addEventListener('canplaythrough', playAudioWhenReady, { once: true });

                            // Now set source and load
                            audio.src = versionData.url;
                            audio.load();
                        }
                    }
                }

                // Notify parent component
                onVersionChange?.(version);
                toast.success(`Switched to version ${version}`);
            }
        } catch (error) {
            console.error("Error updating version:", error);
            toast.error("Failed to update version");
        } finally {
            setLoading(false);
        }
    };

    const handleSplitDialogue = async () => {
        const confirm = window.confirm(
            "Are you sure you want to split this dialogue back into individual sentences?"
        );

        if (!confirm) return;

        setLoading(true);
        try {
            const response = await axios.post(SPLIT_DIALOGUE, {
                projectId,
                dialogueId: selectedDialogueId
            });

            if (response.data.success) {
                toast.success("Dialogue split successfully!");
                onClose();
                window.location.reload();
            } else {
                toast.error(response.data.message || "Failed to split dialogue");
            }
        } catch (error) {
            console.error("Error splitting dialogue:", error);
            toast.error(error.response?.data?.message || "An error occurred while splitting the dialogue");
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (event) => {
        if (hasUnsavedChanges) {
            const confirmed = window.confirm(
                "You have unsaved text changes. Are you sure you want to close? Your changes will be lost."
            );
            if (!confirmed) {
                return; // Don't close if user cancels
            }
        }
        onClose(event);
    };

    return (
        <>
            {loading && <Loader Load={loading} />}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        padding: '16px 16px 8px 0px',
                        width: 'auto',
                        maxWidth: 'none',
                    },
                    '& .css-mhc70k-MuiGrid-root > .MuiGrid-item': {
                        paddingLeft: 1,
                        paddingTop: 0
                    }
                }}
            >
                <Stack spacing={2} sx={{ width: '420px' }}>
                    {!hasAudioVersions && (
                        <Alert
                            severity="warning"
                            sx={{
                                width: '90%',
                                '&.MuiPaper-root.MuiAlert-root': {
                                    marginLeft: '15px'
                                }
                            }}
                        >
                            No audio has been generated for this dialogue yet.
                        </Alert>
                    )}

                    <Grid item xs={12} >
                        <TextField
                            sx={{ marginLeft: '15px', width: '96%' }}
                            multiline
                            rows={4}
                            value={editedText}
                            onChange={(e) => {
                                setEditedText(e.target.value);
                                setHasUnsavedChanges(true);
                            }}
                            placeholder="Edit performance text..."
                            variant="outlined"
                            size="small"
                        />
                    </Grid>

                    {/* First Row */}
                    <Grid container spacing={2} >
                        {/* Left Column: Play Controls */}
                        <Grid item xs={6} sx={{ paddingLeft: '0px' }}>
                            <Stack spacing={1} alignItems="center">
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="primary"
                                    onClick={onPlayFromHere}
                                    disabled={!hasAudioVersions}
                                    startIcon={<span role="img" aria-label="play from here">▶️</span>}
                                    sx={{
                                        justifyContent: 'flex-start',
                                        textTransform: 'none',
                                    }}
                                >
                                    Play From Here
                                </Button>
                                <Button
                                    fullWidth
                                    variant="text"
                                    color="primary"
                                    disabled={!hasAudioVersions}
                                    onClick={handlePlaySentence}
                                    startIcon={<span role="img" aria-label="play">⏯️</span>}
                                    sx={{
                                        justifyContent: 'flex-start',
                                        textTransform: 'none',
                                    }}
                                >
                                    Play Sentence
                                </Button>
                            </Stack>
                        </Grid>

                        {/* Right Column: Selectors and Badges */}
                        <Grid item xs={6} paddingRight={2}>
                            <Stack spacing={1}>
                                <Select
                                    fullWidth
                                    value={selectedVersion}
                                    disabled={!hasAudioVersions}
                                    onChange={(e) => {
                                        const newVersion = e.target.value;
                                        setSelectedVersion(newVersion);
                                        handleVersionChange(newVersion);
                                    }}
                                    size="small"
                                >
                                    {audioVersions.map(version => (
                                        <MenuItem key={version.version} value={version.version}>
                                            {version.version} - {version.version === '0' ? 'Original' : `Version ${version.version}`} ({version.duration.toFixed(2)})
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Select
                                        fullWidth
                                        value={selectedEmotion}
                                        onChange={(e) => setSelectedEmotion(e.target.value)}
                                        size="small"
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>Select emotion</MenuItem>
                                        {emotionList.map((emotion, idx) => (
                                            <MenuItem value={emotion} key={idx}>
                                                {emotion.charAt(0).toUpperCase() + emotion.slice(1)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Badge
                                        badgeContent={1}
                                        color="primary"
                                        onClick={() => handleRegenerateAudio(1)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <AddCircleIcon sx={{ fontSize: '1.7rem' }} />
                                    </Badge>
                                    <Badge
                                        badgeContent={3}
                                        color="primary"
                                        onClick={() => handleRegenerateAudio(3)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <AddCircleIcon sx={{ fontSize: '1.7rem' }} />
                                    </Badge>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>

                    {/* Second Row */}
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <Tooltip title={!canMergeLeft ? "Cannot merge with dialogue from different paragraph" : ""}>
                                <span>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mergeDirection === 'left'}
                                                onChange={() => handleCheckboxChange('left')}
                                                disabled={!canMergeLeft}
                                            />
                                        }
                                        label="With Left"
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={!canMergeRight ? "Cannot merge with dialogue from different paragraph" : ""}>
                                <span>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mergeDirection === 'right'}
                                                onChange={() => handleCheckboxChange('right')}
                                                disabled={!canMergeRight}
                                            />
                                        }
                                        label="With Right"
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                color="primary"
                                onClick={handleSplitDialogue}
                                startIcon={<SplitIcon />}
                                sx={{
                                    justifyContent: 'flex-start',
                                    textTransform: 'none',
                                }}
                            >
                                Split Dialogue
                            </Button>
                        </Grid>
                    </Grid>

                    {/* Hidden audio player */}
                    <ReactAudioPlayer
                        ref={audioPlayerRef}
                        src={audioUrl || ''}
                        controls={false}
                        style={{ display: 'none' }}
                    />
                </Stack>
            </Popover>
        </>
    );
};


PopoverControls.propTypes = {
    open: PropTypes.bool.isRequired,
    anchorEl: PropTypes.any,
    onClose: PropTypes.func.isRequired,
    audioUrl: PropTypes.string,
    onPlayFromHere: PropTypes.func.isRequired,
    selectedDialogueId: PropTypes.string.isRequired,
    adjacentDialogueId: PropTypes.object.isRequired,
    projectId: PropTypes.string.isRequired,
    audioDuration: PropTypes.number,
    dialogue: PropTypes.shape({
        id: PropTypes.string.isRequired,
        audio_versions: PropTypes.object,
        text: PropTypes.string.isRequired
    }),
    onVersionChange: PropTypes.func,
    onAudioGenerated: PropTypes.func,
    mainPlayerStateRef: PropTypes.shape({
        current: PropTypes.object.isRequired
    }),
    mainPlayerRef: PropTypes.shape({
        current: PropTypes.object
    }),
    paragraphInfo: PropTypes.shape({
        currentParagraphId: PropTypes.number,
        leftParagraphId: PropTypes.number,
        rightParagraphId: PropTypes.number
    })
};

export default PopoverControls;
