import React from "react";
import styles from "../../Assets/Styles/GradientCarousel.module.css";

const GradientCarousel = ({ images, characters, activeIndex, setActiveIndex, isLoading  }) => {
    // Use the props if provided, otherwise use internal state
    const activeIndexState = activeIndex !== undefined ? activeIndex : null;
    const setActiveIndexState = setActiveIndex || null;

    const handleNext = () => {
        if (setActiveIndexState) {
            setActiveIndexState((prev) => (prev + 1) % images.length);
        }
    };

    const handlePrev = () => {
        if (setActiveIndexState) {
            setActiveIndexState((prev) => (prev - 1 + images.length) % images.length);
        }
    };

    // Determine which position a card should occupy
    const getPositionClass = (index) => {
        // distance is how far `index` is from the current activeIndex
        const distance = (index - activeIndexState + images.length) % images.length;

        if (distance === 0) return "active";
        if (distance === 1) return "next";
        if (distance === 2) return "next2";
        if (distance === images.length - 1) return "prev";
        if (distance === images.length - 2) return "prev2";
        return "hidden";
    };

    return (
        <div className={styles.carouselContainer}>
            <div className={styles.cardsWrapper}>
                {images.map((imgSrc, i) => (
                    <div
                        key={i}
                        className={`${styles.card} ${styles[getPositionClass(i)]}`}
                        onClick={() => setActiveIndexState && setActiveIndexState(i)}
                    >
                        <div className={styles.cardContainer}>
                            <img src={imgSrc} alt={`Card ${i}`} className={styles.cardImage} />

                            {isLoading && i === activeIndexState && (
                                <div className={styles.spinnerContainer}>
                                    <div className={styles.spinner}></div>
                                </div>
                            )}
                            
                            <div className={styles.characterNameContainer}>
                                {i === activeIndexState ? (
                                    <div className={styles.characterName}>
                                        {characters[i]?.name || "[Character Name]"}
                                    </div>
                                ) : (
                                    <div className={styles.characterName}>
                                        {characters[i]?.name || "[Character Name]"}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

                {/* Carousel Controls */}
                <div className={styles.controlsWrapper}>
                    <button onClick={handlePrev} className={`${styles.navButton} ${styles.prevButton}`}>
                        <span>←</span>
                    </button>
                    <button onClick={handleNext} className={`${styles.navButton} ${styles.nextButton}`}>
                        <span>→</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GradientCarousel;