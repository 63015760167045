// Components/AudiobookDeleteConfirmationModal.js
import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import classes from "../Assets/Styles/TestingAudio.module.css";
import { useNavigate } from "react-router-dom";

function AudiobookDeleteConfirmationModal({
    projectName,
    onClose,
    projectId,
    handleDeleteProjectAPI,
}) {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest(`.${classes["popup-inner"]}`)) {
                onClose();
            }
        };

        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, [isOpen, onClose]);

    useEffect(() => {
        setInterval(() => {
            setIsOpen(true);
        }, 100);
    }, []);

    return (
        <Grid item container className={classes["popup"]}>
            <Grid className={classes["popup-inner"]}>
                <Grid item container justifyContent="space-between">
                    <Grid item fontSize="15px">
                        Do you want to delete {projectName} Audiobook?
                    </Grid>
                </Grid>
                <Grid item container justifyContent="space-evenly" marginTop={1}>
                    <Grid item>
                        <Button
                            color="warning"
                            onClick={async () => {
                                await handleDeleteProjectAPI(projectId);
                                navigate('/audiobooks'); // Navigate to audiobooks list
                                onClose();
                            }}
                        >
                            yes
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button color="primary" onClick={onClose}>
                            no
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AudiobookDeleteConfirmationModal;