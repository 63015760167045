import {
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import AudiobookDeleteConfirmationModal from '../../Components/AudiobookDeleteConfirmationModal';
import Loader from '../../Components/Loader';
import { GET_AUDIOBOOK_DETAILS, DELETE_AUDIOBOOK } from '../../MProjectConst';

const AudiobookActionsScreen = () => {
    const params = useParams();
    const { projectId } = params;
    const navigate = useNavigate();
    const [dataLoader, setDataLoader] = useState(false);
    const [audiobookDetails, setAudiobookDetails] = useState({});
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setDataLoader(true);
            try {
                const response = await axios.get(`${GET_AUDIOBOOK_DETAILS}/${projectId}`);
                setAudiobookDetails(response.data.project);
            } catch (err) {
                console.error(err);
                toast.error('Failed to load audiobook details');
            } finally {
                setDataLoader(false);
            }
        };
        fetchData();
    }, [projectId]);

    const toggleDeleteConfirmModal = () => {
        setShowDeleteConfirmModal(!showDeleteConfirmModal);
    };

    const handleDeleteAudiobook = async (id) => {
        setDeleteLoader(true);
        try {
            const response = await axios.delete(`${DELETE_AUDIOBOOK}/${id}`);
            if (response.data.success) {
                toast.success('Audiobook has been deleted');
                navigate('/audiobooks');
            }
        } catch (error) {
            console.error('Error deleting audiobook:', error);
            toast.error('An error occurred while deleting the audiobook');
        } finally {
            setDeleteLoader(false);
        }
    };

    return (
        <Grid item container justifyContent="center" marginBottom={24.7}>
            <Loader Load={dataLoader || deleteLoader} />
            <ToastContainer position="top-center" theme="dark" />

            <Grid item container xs={7} marginTop={7} direction="column">
                <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
                    Actions - {audiobookDetails.name}
                </Grid>
                <Grid item>
                    <Table sx={{ minWidth: 650, bgcolor: "white" }}>
                        <TableHead bgcolor="#5570dc">
                            <TableRow>
                                <TableCell>Names</TableCell>
                                <TableCell>Actions</TableCell>
                                <TableCell>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Empty table body - ready for future actions */}
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item container marginTop={12} direction="column">
                    <Button
                        size="small"
                        color="error"
                        variant="contained"
                        onClick={() => {
                            toggleDeleteConfirmModal();
                        }}
                    >
                        Delete
                    </Button>
                </Grid>
            </Grid>

            {showDeleteConfirmModal && (
                <AudiobookDeleteConfirmationModal
                    projectName={audiobookDetails.name}
                    projectId={projectId}
                    onClose={toggleDeleteConfirmModal}
                    handleDeleteProjectAPI={handleDeleteAudiobook}
                />
            )}
        </Grid>
    );
};

export default AudiobookActionsScreen;