import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Pagination,
  Stack,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { GET_USERS } from "../MProjectConst";

const UserListScreen = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const searchInputRef = useRef(null);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pageSize: 5,
    totalPages: 0
  });

  // Debounce search term
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // 500ms delay

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const fetchUsers = async (page = 1, search = '') => {
    try {
      setLoading(true);
      // Assuming your API accepts page parameter and search term
      const response = await axios.post(GET_USERS, { page, search });
      console.log("API response:", response.data);

      // Get users from the correct property
      const usersData = response.data.users || [];
      setUsers(usersData);

      // Set pagination info
      if (response.data.pagination) {
        setPagination(response.data.pagination);
      }

      setLoading(false);

      // Restore focus to the search input if it was active
      if (document.activeElement === searchInputRef.current) {
        setTimeout(() => {
          searchInputRef.current?.focus();
        }, 0);
      }
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("Failed to load users");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(1, debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  // Handle page change
  const handlePageChange = (event, newPage) => {
    fetchUsers(newPage, debouncedSearchTerm);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // We don't reset page here anymore, as the actual search will happen with debounce
  };

  // Clear search with focus preservation
  const clearSearch = useCallback(() => {
    setSearchTerm('');
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 0);
  }, []);

  const navigate = useNavigate();

  const deleteHandler = (user) => {
    // Implement delete functionality here
    console.log("Delete user:", user);
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: "50vh" }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: "50vh" }}>
        <div>{error}</div>
      </Grid>
    );
  }

  return (
    <Grid item container justifyContent="center">
      <Grid item container xs={7} marginTop={7} direction="column">
        <Grid item container justifyContent="space-between" alignItems="center" mb={2}>
          <Grid item sx={{ fontSize: "40px", fontWeight: "600" }}>
            Users
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search users..."
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              inputRef={searchInputRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      size="small"
                      onClick={clearSearch}
                      disabled={!searchTerm}
                    >
                      Clear
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Table sx={{ minWidth: 650, bgcolor: "white" }}>
            <TableHead bgcolor="#5570dc">
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>NAME</TableCell>
                <TableCell>EMAIL</TableCell>
                <TableCell>Admin</TableCell>
                <TableCell>ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length > 0 ? (
                users.map((user, index) => {
                  // Calculate the actual continuous index based on the current page and page size
                  const actualIndex = (pagination.page - 1) * pagination.pageSize + index + 1;

                  return (
                    <TableRow key={user.id}>
                      <TableCell>{actualIndex}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.isAdmin ? <>True</> : <>False</>}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          onClick={() => navigate(`/admin/user/${user.id}`)}
                        >
                          Edit
                        </Button>
                        &nbsp;
                        <Button
                          color="error"
                          onClick={() => deleteHandler(user)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No users found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>

        {/* Pagination */}
        <Grid item mt={3} container justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            Showing {users.length} of {pagination.total} users
          </Typography>
          <Stack spacing={2}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserListScreen;