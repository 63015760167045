// UnifiedVideoPlayer.js
import "react-video-seek-slider/styles.css";
import { VideoSeekSlider } from "react-video-seek-slider";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { primaryColor } from "../../utils/constants";
import InputMask from "react-input-mask";
import SyncIcon from "@mui/icons-material/Sync";
import classes from "../../Assets/Styles/UnifiedVideoPlayer.module.css";

const UnifiedVideoPlayer = memo(({
    source,
    audioSource,
    seekTime,
    displayAudioTime,
    seekTimeIndex,
    setPausePlay,
    layout = 'vertical', // 'vertical' or 'horizontal'
    dualAudio = false,
    showDebug = false,
    enableScroll = false,
    customClasses = {},
    onTimeSync,
    showSubtitles = false,  // New prop to enable/disable subtitles
    subtitles = [],         // New prop for subtitle data
}) => {
    // State variables
    const [isBuffering, setIsBuffering] = useState(false);
    const videoRef = useRef(null);
    const originalPlayer = useRef(null);
    const dialoguePlayer = useRef(null);
    const animationFrameRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0);
    const [progress, setProgress] = useState(0);
    const [maxTime, setMaxTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(0.5);
    const [audioSpeed, setAudioSpeed] = useState(1);
    const [selectedAudioSource, setSelectedAudioSource] = useState('original');
    const [pauseTime, setPauseTime] = useState(0);
    const [isSeeking, setIsSeeking] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [debugInfo, setDebugInfo] = useState({
        videoTime: 0,
        audioTime: 0,
        syncDiff: 0
    });
    const [currentSubtitle, setCurrentSubtitle] = useState(null);  // To track current subtitle

    // Constants
    const DIALOGUE_OFFSET = 0;
    const currentPlayer = selectedAudioSource === 'original' ? originalPlayer : dialoguePlayer;
    const [inputTime, setInputTime] = useState("");
    const [isEditing, setIsEditing] = useState(false);

    const handleTimeInputChange = (e) => {
        const value = e.target.value;
        setInputTime(value);
        setIsEditing(true);

        // Parse the input time into milliseconds
        const [hours, minutes, seconds, milliseconds] = value.split(/[:.]/).map(Number);
        const newCurrentTime =
            (hours || 0) * 3600 * 1000 +
            (minutes || 0) * 60 * 1000 +
            (seconds || 0) * 1000 +
            (milliseconds || 0) * 10;

        // If within the maxTime, seek immediately
        if (newCurrentTime <= maxTime) {
            videoRef.current.currentTime = newCurrentTime / 1000;
            setCurrentTime(newCurrentTime);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            const [hours, minutes, seconds, milliseconds] = inputTime.split(/[:.]/).map(Number);
            const enteredTime =
                (hours || 0) * 3600 * 1000 +
                (minutes || 0) * 60 * 1000 +
                (seconds || 0) * 1000 +
                (milliseconds || 0) * 10;

            const seekTime = Math.min(enteredTime, maxTime);
            videoRef.current.currentTime = seekTime / 1000;
            setCurrentTime(seekTime);
            setIsEditing(false);

            // Call sync callback if provided
            if (onTimeSync) {
                onTimeSync(seekTime / 1000);
            }
        }
    };

    // Update input time when video time changes
    useEffect(() => {
        if (!isEditing) {
            const formattedTime = new Date(currentTime).toISOString().substr(11, 12);
            setInputTime(formattedTime);
        }
    }, [currentTime, isEditing]);

    // Scroll handling for horizontal layout
    useEffect(() => {
        if (layout === 'horizontal' && enableScroll) {
            const handleScroll = () => {
                if (window.scrollY < 95) {
                    setIsScrolled(108 - window.scrollY);
                } else {
                    setIsScrolled(10);
                }
            };
            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, [layout, enableScroll]);

    // Format time display
    const formatTime = (seconds, decimalPlaces = 2) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = (seconds % 60).toFixed(decimalPlaces);
        return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(3 + decimalPlaces, '0')}`;
    };

    // Time update handling
    const updateCurrentTime = useCallback(() => {
        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime * 1000);
            animationFrameRef.current = requestAnimationFrame(updateCurrentTime);
        }
    }, []);

    const handlePlay = useCallback(() => {
        animationFrameRef.current = requestAnimationFrame(updateCurrentTime);
    }, [updateCurrentTime]);

    // Audio synchronization
    const syncAudioWithVideo = useCallback(() => {
        if (isSeeking || !dualAudio) return;

        if (videoRef.current && currentPlayer.current) {
            const videoTime = videoRef.current.currentTime;
            const targetAudioTime = videoTime - (selectedAudioSource === 'dialogue' ? DIALOGUE_OFFSET : 0);
            const audioTime = currentPlayer.current.currentTime;
            const timeDiff = Math.abs(audioTime - targetAudioTime);

            if (showDebug) {
                setDebugInfo({
                    videoTime: videoTime.toFixed(3),
                    audioTime: audioTime.toFixed(3),
                    syncDiff: timeDiff.toFixed(3)
                });
            }

            if (timeDiff > 0.1) {
                currentPlayer.current.currentTime = targetAudioTime;
            }
        }
    }, [isSeeking, selectedAudioSource, currentPlayer, dualAudio, showDebug]);

    // Event handlers
    const handlePause = () => {
        cancelAnimationFrame(animationFrameRef.current);
    };

    const handleDataLoaded = () => {
        if (currentPlayer.current) {
            setMaxTime(currentPlayer.current.duration * 1000);
        }
    };

    const handleProgress = () => {
        if (currentPlayer.current) {
            const buffer = currentPlayer.current.buffered;
            if (buffer.length > 0) {
                let currentBuffer = 0;
                const inSeconds = currentPlayer.current.currentTime;
                for (let i = 0; i < buffer.length; i++) {
                    if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
                        currentBuffer = i;
                        break;
                    }
                }
                setProgress(buffer.end(currentBuffer) * 1000 || 0);
            }
        }
    };

    const handleWaiting = () => {
        setIsBuffering(true);
        if (currentPlayer.current) {
            currentPlayer.current.pause();
        }
    };

    const handleCanPlay = () => {
        if (isBuffering) {
            setIsBuffering(false);
            if (isPlaying) {
                attemptPlay(videoRef.current);
                if (currentPlayer.current) {
                    attemptPlay(currentPlayer.current);
                }
            }
        }
    };

    // Play attempt with error handling
    const attemptPlay = async (mediaElement) => {
        if (!mediaElement) return;
        try {
            if (mediaElement.readyState >= 3) {
                await mediaElement.play();
            } else {
                const handleCanPlayOnce = async () => {
                    try {
                        await mediaElement.play();
                    } catch (error) {
                        console.error("Error playing media after canplay:", error);
                    }
                    mediaElement.removeEventListener('canplay', handleCanPlayOnce);
                };
                mediaElement.addEventListener('canplay', handleCanPlayOnce);
            }
        } catch (error) {
            console.error("Error attempting to play media:", error);
        }
    };

    // Playback controls
    const togglePlayPause = async () => {
        if (dualAudio) {
            // Existing dual audio logic
            if (isPlaying) {
                try {
                    currentPlayer.current?.pause();
                    videoRef.current?.pause();
                    setPausePlay(false);
                } catch (error) {
                    console.error("Error pausing media:", error);
                }
                setIsPlaying(false);
            } else {
                await attemptPlay(videoRef.current);
                if (currentPlayer.current) {
                    await attemptPlay(currentPlayer.current);
                }
                setPausePlay(true);
                setIsPlaying(true);
            }
        } else {
            // Single audio mode logic
            if (isPlaying) {
                try {
                    videoRef.current?.pause();
                    setPausePlay(false);
                    setIsPlaying(false);
                } catch (error) {
                    console.error("Error pausing video:", error);
                }
            } else {
                try {
                    await videoRef.current?.play();
                    setPausePlay(true);
                    setIsPlaying(true);
                } catch (error) {
                    console.error("Error playing video:", error);
                }
            }
        }
    };

    // Control handlers
    const handleVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);

        if (dualAudio) {
            // Dual audio mode - use currentPlayer
            if (currentPlayer.current) {
                currentPlayer.current.volume = newVolume;
            }
        } else {
            // Single audio mode - use video element directly
            if (videoRef.current) {
                videoRef.current.volume = newVolume;
            }
        }
        setVolume(newVolume);
    };

    const handleSpeedChange = (e) => {
        const newSpeed = parseFloat(e.target.value);
        if (videoRef.current) {
            videoRef.current.playbackRate = newSpeed;
        }
        if (currentPlayer.current) {
            currentPlayer.current.playbackRate = newSpeed;
        }
        setAudioSpeed(newSpeed);
    };

    const handleAudioSourceChange = async (e) => {
        if (!dualAudio) return;

        const newSource = e.target.value;
        const wasPlaying = isPlaying;
        const videoTime = videoRef.current ? videoRef.current.currentTime : 0;

        if (currentPlayer.current) {
            try {
                currentPlayer.current.pause();
            } catch (error) {
                console.error("Error pausing audio during source change:", error);
            }
        }
        if (videoRef.current) {
            try {
                videoRef.current.pause();
            } catch (error) {
                console.error("Error pausing video during source change:", error);
            }
        }

        setIsPlaying(false);
        setPausePlay(false);
        setSelectedAudioSource(newSource);

        setTimeout(async () => {
            const newPlayer = newSource === 'original' ? originalPlayer : dialoguePlayer;
            if (newPlayer.current && videoRef.current) {
                newPlayer.current.currentTime = videoTime - (newSource === 'dialogue' ? DIALOGUE_OFFSET : 0);
                newPlayer.current.playbackRate = audioSpeed;
                videoRef.current.currentTime = videoTime;
                videoRef.current.playbackRate = audioSpeed;

                if (wasPlaying) {
                    await attemptPlay(newPlayer.current);
                    await attemptPlay(videoRef.current);
                    setIsPlaying(true);
                    setPausePlay(true);
                }
            }
        }, 0);
    };

    const handleTimeChange = (time) => {
        const newTime = time / 1000;

        if (dualAudio) {
            if (currentPlayer.current && videoRef.current) {
                setIsSeeking(true);
                currentPlayer.current.currentTime = newTime;
                videoRef.current.currentTime = newTime + (selectedAudioSource === 'dialogue' ? DIALOGUE_OFFSET : 0);
                setCurrentTime(time);
            }
        } else {
            if (videoRef.current) {
                setIsSeeking(true);
                videoRef.current.currentTime = newTime;
                setCurrentTime(time);
            }
        }
    };

    // Effect for seeking completion
    useEffect(() => {
        const handleSeeked = () => {
            setIsSeeking(false);
        };

        if (currentPlayer.current) {
            currentPlayer.current.addEventListener('seeked', handleSeeked);
        }
        if (videoRef.current) {
            videoRef.current.addEventListener('seeked', handleSeeked);
        }

        return () => {
            if (currentPlayer.current) {
                currentPlayer.current.removeEventListener('seeked', handleSeeked);
            }
            if (videoRef.current) {
                videoRef.current.removeEventListener('seeked', handleSeeked);
            }
        };
    }, []);

    // Effect for player event listeners
    useEffect(() => {
        const players = [originalPlayer.current, dialoguePlayer.current].filter(Boolean);
        players.forEach(player => {
            if (player) {
                player.addEventListener("play", handlePlay);
                player.addEventListener("pause", handlePause);
                player.addEventListener("loadeddata", handleDataLoaded);
                player.addEventListener("progress", handleProgress);
            }
        });

        return () => {
            players.forEach(player => {
                if (player) {
                    player.removeEventListener("play", handlePlay);
                    player.removeEventListener("pause", handlePause);
                    player.removeEventListener("loadeddata", handleDataLoaded);
                    player.removeEventListener("progress", handleProgress);
                }
            });
            cancelAnimationFrame(animationFrameRef.current);
        };
    }, [handlePlay]);

    // Effect for time display
    useEffect(() => {
        displayAudioTime((currentTime / 1000).toFixed(3));
    }, [currentTime, displayAudioTime]);

    // Effect for external seek actions
    useEffect(() => {
        if ((seekTimeIndex === 1 || seekTimeIndex === 2) && currentPlayer.current && videoRef.current) {
            setIsSeeking(true);
            currentPlayer.current.currentTime = seekTime;
            videoRef.current.currentTime = seekTime + (audioSource === 'dialogue' ? DIALOGUE_OFFSET : 0);
            setCurrentTime(seekTime * 1000);
            setIsSeeking(false);
        }
    }, [seekTimeIndex, seekTime, audioSource]);

    // Effect for playback sync
    useEffect(() => {
        let syncInterval;

        if (isPlaying && !isSeeking && dualAudio) {
            syncInterval = setInterval(syncAudioWithVideo, 50);
        }

        return () => {
            if (syncInterval) {
                clearInterval(syncInterval);
            }
        };
    }, [isPlaying, isSeeking, syncAudioWithVideo, dualAudio]);

    // Effect for buffering handlers
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.addEventListener('waiting', handleWaiting);
            video.addEventListener('canplay', handleCanPlay);
            video.addEventListener('playing', handleCanPlay);

            return () => {
                video.removeEventListener('waiting', handleWaiting);
                video.removeEventListener('canplay', handleCanPlay);
                video.removeEventListener('playing', handleCanPlay);
            };
        }
    }, [isPlaying]);

    // Layout-specific classes and styles
    const containerStyles = layout === 'horizontal'
        ? {
            width: '94.1%',
            top: `${isScrolled}px`,  // Add px unit
            position: 'sticky',      // Add position sticky for horizontal layout
            zIndex: 1000,           // Add zIndex to keep it above other elements
            ...customClasses.containerStyles // Change to containerStyles
        }
        : customClasses.containerStyles || {};

    useEffect(() => {
        if (!dualAudio && videoRef.current) {
            // Set up video-only playback handling
            const videoElement = videoRef.current;

            const handleVideoTimeUpdate = () => {
                setCurrentTime(videoElement.currentTime * 1000);
            };

            const handleVideoLoadedMetadata = () => {
                setMaxTime(videoElement.duration * 1000);
            };

            videoElement.addEventListener('timeupdate', handleVideoTimeUpdate);
            videoElement.addEventListener('loadedmetadata', handleVideoLoadedMetadata);

            return () => {
                videoElement.removeEventListener('timeupdate', handleVideoTimeUpdate);
                videoElement.removeEventListener('loadedmetadata', handleVideoLoadedMetadata);
            };
        }
    }, [dualAudio]);

    // NEW: Effect for subtitle handling
    useEffect(() => {
        if (!showSubtitles || !subtitles || subtitles.length === 0) {
            setCurrentSubtitle(null);
            return;
        }

        // Check for subtitle that should be displayed at current time
        const videoTimeInSeconds = currentTime / 1000;

        // Find the subtitle that matches the current timestamp
        const activeSubtitle = subtitles.find(subtitle => {
            // Convert time strings (HH:MM:SS,mmm) to seconds
            const startTime = timeStringToSeconds(subtitle.startTime);
            const endTime = timeStringToSeconds(subtitle.endTime);

            return videoTimeInSeconds >= startTime && videoTimeInSeconds <= endTime;
        });

        setCurrentSubtitle(activeSubtitle);
    }, [currentTime, showSubtitles, subtitles]);

    // Helper function to convert subtitle time format to seconds
    const timeStringToSeconds = (timeString) => {
        const [hours, minutes, rest] = timeString.split(':');
        const [seconds, milliseconds] = rest.split(',');

        return (
            parseInt(hours) * 3600 +
            parseInt(minutes) * 60 +
            parseInt(seconds) +
            parseInt(milliseconds) / 1000
        );
    };

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={layout === 'horizontal' ? 12 : 5.85}
            xl={layout === 'horizontal' ? 12 : 5.85}
            container
            direction="row"
            height={layout === 'horizontal' ? 'auto' : '715px'}
            style={containerStyles}  // Use style instead of directly applying classes
            className={customClasses.container} // Keep className for other classes
        >
            <Grid
                item
                container
                className={customClasses.videoContainer}
                sx={{ position: 'relative' }} // Ensure position is relative for subtitle overlay
            >
                <video
                    ref={videoRef}
                    width="100%"
                    height="100%"
                    src={source}
                    // Remove muted when we're not using dual audio
                    muted={dualAudio}
                    onWaiting={handleWaiting}
                    onCanPlay={handleCanPlay}
                    onPlaying={handleCanPlay}
                    style={{ backgroundColor: '#000' }}
                >
                    <source type="video/mp4" />
                </video>

                {/* Subtitle overlay */}
                {showSubtitles && currentSubtitle && (
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '5%',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            maxWidth: '80%',
                            padding: '8px 16px',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            color: '#fff',
                            borderRadius: '4px',
                            textAlign: 'center',
                            fontSize: '20px',
                            zIndex: 10,
                            textShadow: '1px 1px 2px rgba(0, 0, 0, 0.8)'
                        }}
                    >
                        {currentSubtitle.text}
                    </div>
                )}

                {dualAudio && (
                    <>
                        <audio
                            ref={originalPlayer}
                            style={{ display: 'none' }}
                            src={source}
                        />
                        <audio
                            ref={dialoguePlayer}
                            style={{ display: 'none' }}
                            src={audioSource}
                        />
                    </>
                )}
            </Grid>

            {showDebug && (
                <Grid item container padding={1} style={{ background: '#f5f5f5' }}>
                    <Typography variant="caption" style={{ fontFamily: 'monospace' }}>
                        Source: <strong>{selectedAudioSource}</strong> |
                        Video Time: {debugInfo.videoTime}s |
                        Audio Time: {debugInfo.audioTime}s |
                        Sync Diff: {debugInfo.syncDiff}s
                    </Typography>
                </Grid>
            )}

            {/* Controls Section */}
            <Grid
                item
                container
                className={customClasses.controlsContainer}
                sx={{ minHeight: '120px' }}
            >
                <Grid
                    paddingLeft={1}
                    paddingRight={1}
                    item
                    sx={{
                        width: layout === 'horizontal' ? 3000 : '100%',
                        height: 20,
                    }}
                    bgcolor="#EBEBEB"
                >
                    <VideoSeekSlider
                        max={maxTime}
                        currentTime={currentTime}
                        bufferTime={progress}
                        onChange={handleTimeChange}
                        limitTimeTooltipBySides={true}
                        secondsPrefix="00:"
                        minutesPrefix="0:"
                    />
                </Grid>

                <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    gap={1}
                    marginLeft={1}
                    marginBottom={1}
                    sx={{
                        flexWrap: 'wrap',
                        minHeight: '80px',
                        padding: '8px'
                    }}
                >
                    {/* Rewind Button */}
                    <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
                        <FastRewindIcon
                            fontSize="large"
                            onClick={() => handleTimeChange(currentTime - 100)}
                        />
                    </Grid>

                    {/* Play/Pause Button */}
                    <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
                        {isPlaying ? (
                            <PauseCircleIcon
                                fontSize="large"
                                onClick={togglePlayPause}
                            />
                        ) : (
                            <PlayCircleIcon
                                fontSize="large"
                                onClick={togglePlayPause}
                            />
                        )}
                    </Grid>

                    {/* Forward Button */}
                    <Grid item sx={{ color: `${primaryColor}`, cursor: "pointer" }}>
                        <FastForwardIcon
                            fontSize="large"
                            onClick={() => handleTimeChange(currentTime + 100)}
                        />
                    </Grid>

                    <Grid item container alignItems="center" gap={0.5} sx={{ maxWidth: '135px' }}>
                        <Grid item>
                            <InputMask
                                mask="99:99:99.99"
                                value={inputTime}
                                onChange={handleTimeInputChange}
                                onKeyPress={handleKeyPress}
                                className={classes["AudioWorkPlaceTimelineEditTranslation"]}
                                placeholder="HH:MM:SS.MS"
                            />
                        </Grid>
                        <Grid item sx={{ color: primaryColor, cursor: "pointer" }}>
                            <SyncIcon
                                fontSize="large"
                                onClick={() => {
                                    if (onTimeSync) {
                                        onTimeSync(currentTime / 1000);
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Audio Source Selection */}
                    {dualAudio && (
                        <>
                            <Grid>Audio:</Grid>
                            <Grid item xs={1.5}>
                                <select
                                    value={selectedAudioSource}
                                    onChange={handleAudioSourceChange}
                                    className={customClasses.selector}
                                >
                                    <option value="original">Original Audio</option>
                                    <option value="dialogue">Dialogue Only</option>
                                </select>
                            </Grid>
                        </>
                    )}

                    {/* Playback Speed Selection */}
                    <Grid>Speed:</Grid>
                    <Grid item xs={1}>
                        <select
                            value={audioSpeed}
                            onChange={handleSpeedChange}
                            className={customClasses.selector}
                        >
                            <option value="0.07">0.07x</option>
                            <option value="0.1">0.1x</option>
                            <option value="0.15">0.15x</option>
                            <option value="0.25">0.25x</option>
                            <option value="0.5">0.5x</option>
                            <option value="1">1.0x</option>
                            <option value="1.5">1.5x</option>
                            <option value="2">2.0x</option>
                            <option value="2.5">2.5x</option>
                            <option value="3">3.0x</option>
                        </select>
                    </Grid>

                    {/* Pause Time Selection */}
                    <Grid>Pause:</Grid>
                    <Grid item xs={1}>
                        <select
                            value={pauseTime}
                            onChange={(event) => setPauseTime(event.target.value)}
                            className={customClasses.selector}
                        >
                            <option value="0">0.0s</option>
                            <option value="1">1.0s</option>
                            <option value="2">2.0s</option>
                            <option value="3">3.0s</option>
                            <option value="4">4.0s</option>
                            <option value="5">5.0s</option>
                            <option value="6">6.0s</option>
                            <option value="7">7.0s</option>
                            <option value="8">8.0s</option>
                        </select>
                    </Grid>

                    {/* Volume Control */}
                    <Grid item sx={{ width: 200 }}>
                        <Stack spacing={2} direction="row" alignItems="center">
                            <VolumeDown />
                            <Slider
                                value={volume}
                                onChange={handleVolumeChange}
                                min={0}
                                max={1}
                                step={0.1}
                                aria-label="Volume"
                                sx={{ color: `${primaryColor}` }}
                            />
                            <VolumeUp />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default UnifiedVideoPlayer;