// Components/Validations/ProtectedRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Store } from "../../Store";

export default function ProtectedRoute({ children }) {
  const { state } = useContext(Store);
  const { userInfo } = state;

  // First check localStorage if userInfo is not in state
  if (!userInfo) {
    const savedUserInfo = localStorage.getItem("userInfo");
    if (savedUserInfo) {
      return children;
    }
  }

  return userInfo ? children : <Navigate to="/" />;
}