import React, { useState, useRef, useEffect } from 'react';
import styles from '../../Assets/Styles/EmotionWheel.module.css';
import { EXPANDED_EMOTIONS, WHEEL_CONFIG } from './DemoConstants';

const EmotionWheel = ({ onEmotionSelect, selectedEmotion }) => {
    const svgRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [markerPosition, setMarkerPosition] = useState({ x: WHEEL_CONFIG.centerX, y: WHEEL_CONFIG.centerY });
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

    // Function to get coordinates relative to SVG
    const getSVGCoordinates = (event) => {
        const svg = svgRef.current;
        if (!svg) return { x: 0, y: 0 };

        const rect = svg.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        return { x, y };
    };

    // Calculate distance from center
    const getDistanceFromCenter = (x, y) => {
        return Math.sqrt(
            Math.pow(x - WHEEL_CONFIG.centerX, 2) +
            Math.pow(y - WHEEL_CONFIG.centerY, 2)
        );
    };

    // Clamp coordinates to the wheel
    const clampToWheel = (x, y) => {
        const distance = getDistanceFromCenter(x, y);

        if (distance <= WHEEL_CONFIG.radius) {
            return { x, y };
        } else {
            // Normalize to edge of circle
            const angle = Math.atan2(y - WHEEL_CONFIG.centerY, x - WHEEL_CONFIG.centerX);
            const newX = WHEEL_CONFIG.centerX + WHEEL_CONFIG.radius * Math.cos(angle);
            const newY = WHEEL_CONFIG.centerY + WHEEL_CONFIG.radius * Math.sin(angle);
            return { x: newX, y: newY };
        }
    };

    // Mouse event handlers
    const handleMouseDown = (event) => {
        event.preventDefault();
        setIsDragging(true);
        const coords = getSVGCoordinates(event);
        const clampedCoords = clampToWheel(coords.x, coords.y);
        setMarkerPosition(clampedCoords);
        setTooltipPosition(clampedCoords);
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;

        const coords = getSVGCoordinates(event);
        const clampedCoords = clampToWheel(coords.x, coords.y);
        setMarkerPosition(clampedCoords);
        setTooltipPosition(clampedCoords);
    };

    const handleTouchMove = (event) => {
        if (!isDragging || !event.touches[0]) return;

        event.preventDefault();
        const touch = event.touches[0];
        const svg = svgRef.current;
        const rect = svg.getBoundingClientRect();
        const x = touch.clientX - rect.left;
        const y = touch.clientY - rect.top;

        const clampedCoords = clampToWheel(x, y);
        setMarkerPosition(clampedCoords);
        setTooltipPosition(clampedCoords);
    };

    const handleMouseUp = (event) => {
        if (!isDragging) return;

        setIsDragging(false);
        const coords = getSVGCoordinates(event);
        const clampedCoords = clampToWheel(coords.x, coords.y);

        // Find the closest emotion
        let closestEmotion = null;
        let closestDistance = Infinity;

        EXPANDED_EMOTIONS.forEach(emotion => {
            const distance = Math.sqrt(
                Math.pow(emotion.position.x - clampedCoords.x, 2) +
                Math.pow(emotion.position.y - clampedCoords.y, 2)
            );

            if (distance < closestDistance) {
                closestDistance = distance;
                closestEmotion = emotion;
            }
        });

        if (closestEmotion) {
            onEmotionSelect(closestEmotion.id);
            setMarkerPosition({
                x: closestEmotion.position.x,
                y: closestEmotion.position.y
            });
        }
    };

    const handleTouchEnd = (event) => {
        if (!isDragging) return;

        setIsDragging(false);
        const clampedCoords = markerPosition; // Use the last position from touch move

        // Find the closest emotion
        let closestEmotion = null;
        let closestDistance = Infinity;

        EXPANDED_EMOTIONS.forEach(emotion => {
            const distance = Math.sqrt(
                Math.pow(emotion.position.x - clampedCoords.x, 2) +
                Math.pow(emotion.position.y - clampedCoords.y, 2)
            );

            if (distance < closestDistance) {
                closestDistance = distance;
                closestEmotion = emotion;
            }
        });

        if (closestEmotion) {
            onEmotionSelect(closestEmotion.id);
            setMarkerPosition({
                x: closestEmotion.position.x,
                y: closestEmotion.position.y
            });
        }
    };

    // Set initial marker position based on selectedEmotion
    useEffect(() => {
        const selectedEmotionObj = EXPANDED_EMOTIONS.find(e => e.id === selectedEmotion);
        if (selectedEmotionObj) {
            setMarkerPosition({
                x: selectedEmotionObj.position.x,
                y: selectedEmotionObj.position.y
            });
        }
    }, [selectedEmotion]);

    // Find selected emotion for tooltip
    const activeEmotion = EXPANDED_EMOTIONS.find(e => e.id === selectedEmotion);

    return (
        <div className={styles['emotion-wheel']}>
            <svg
                ref={svgRef}
                width="300"
                height="380"
                viewBox="0 0 300 380"
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseLeave={() => {
                    setIsDragging(false);
                }}
                style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
            >
                <defs>
                    <radialGradient id="wheelGrad" cx="50%" cy="50%" r="50%">
                        <stop offset="0%" stopColor="#b2a49b" />
                        <stop offset="100%" stopColor="#818283" />
                    </radialGradient>

                    {/* Glow filter for selected emotion */}
                    <filter id="glow" x="-30%" y="-30%" width="160%" height="160%">
                        <feGaussianBlur stdDeviation="3" result="blur" />
                        <feComposite in="SourceGraphic" in2="blur" operator="over" />
                    </filter>
                </defs>

                {/* Wheel background */}
                <circle
                    cx={WHEEL_CONFIG.centerX}
                    cy={WHEEL_CONFIG.centerY}
                    r={WHEEL_CONFIG.radius}
                    fill="url(#wheelGrad)"
                />

                {/* Light gradient overlay for better visibility */}
                <circle
                    cx={WHEEL_CONFIG.centerX}
                    cy={WHEEL_CONFIG.centerY}
                    r={WHEEL_CONFIG.radius}
                    fill="none"
                    strokeOpacity="0.6"
                    strokeDasharray="5,15"
                />

                {/* Crosshair lines */}
                <line
                    x1={WHEEL_CONFIG.centerX}
                    y1={WHEEL_CONFIG.centerY - WHEEL_CONFIG.radius}
                    x2={WHEEL_CONFIG.centerX}
                    y2={WHEEL_CONFIG.centerY + WHEEL_CONFIG.radius}
                    stroke="rgba(255,255,255,0.1)"
                    strokeWidth="1"
                />
                <line
                    x1={WHEEL_CONFIG.centerX - WHEEL_CONFIG.radius}
                    y1={WHEEL_CONFIG.centerY}
                    x2={WHEEL_CONFIG.centerX + WHEEL_CONFIG.radius}
                    y2={WHEEL_CONFIG.centerY}
                    stroke="rgba(255,255,255,0.1)"
                    strokeWidth="1"
                />

                {/* Only show the selected emotion */}
                {selectedEmotion && (
                    <g>
                        <circle
                            cx={markerPosition.x}
                            cy={markerPosition.y}
                            r={15}
                            fill="#fde4cd"
                            stroke="#313130"
                            strokeWidth="2"
                            filter="url(#glow)"
                        />
                    </g>
                )}

                {/* Draggable marker for when user is selecting */}
                {isDragging && (
                    <circle
                        cx={markerPosition.x}
                        cy={markerPosition.y}
                        r="10"
                        fill="#d9b28f"
                        stroke="#fff"
                        strokeWidth="1"
                        opacity="0.8"
                    />
                )}

                {/* Axis labels */}
                <text x={WHEEL_CONFIG.centerX} y="60" fontSize="11" textAnchor="middle" fill="white" fontWeight="bold">
                    Aroused
                </text>
                <text x={WHEEL_CONFIG.centerX} y="330" fontSize="11" textAnchor="middle" fill="white" fontWeight="bold">
                    Unaroused
                </text>
                <text
                    x="30"
                    y={WHEEL_CONFIG.centerY - 10}
                    fontSize="11"
                    textAnchor="middle"
                    fill="white"
                    fontWeight="bold"
                    transform={`rotate(-90, 30, ${WHEEL_CONFIG.centerY})`}
                >
                    Negative
                </text>
                <text
                    x="270"
                    y={WHEEL_CONFIG.centerY - 7}
                    fontSize="11"
                    textAnchor="middle"
                    fill="white"
                    fontWeight="bold"
                    transform={`rotate(90, 270, ${WHEEL_CONFIG.centerY})`}
                >
                    Positive
                </text>
            </svg>

            {/* Display selected emotion name */}
            <div style={{
                color: 'white',
                fontSize: '16px',
                fontWeight: 'bold'
            }}>
                {activeEmotion ? activeEmotion.name : 'No emotion selected'}
            </div>
        </div>
    );
};

export default EmotionWheel;