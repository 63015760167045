import React, { useState, useRef, useEffect } from 'react';
import styles from '../../Assets/Styles/CountrySelector.module.css';
import mapImage from '../../Assets/Images/globe.png'; // Your map

// Outer container size
const SVG_WIDTH = 320;
const SVG_HEIGHT = 380;

// Define how big you want the map itself to be inside the container
// (Pick values smaller than the container so there's a margin around it)
const MAP_IMAGE_WIDTH = 320;
const MAP_IMAGE_HEIGHT = 260;

// Calculate offsets so the map is centered in the container
const MAP_X_OFFSET = (SVG_WIDTH - MAP_IMAGE_WIDTH) / 2;
const MAP_Y_OFFSET = (SVG_HEIGHT - MAP_IMAGE_HEIGHT) / 2;

// For the bounding circle, let's center it at the middle of the map image
// You can also remove this logic if you don't want a circular clamp
const CENTER_X = MAP_X_OFFSET + MAP_IMAGE_WIDTH / 2;
const CENTER_Y = MAP_Y_OFFSET + MAP_IMAGE_HEIGHT / 2;
const RADIUS = 120; // tweak as needed

// Hard-coded country positions within the map area
// These must be manually adjusted so the marker lands on the correct spots
const COUNTRIES = [
    // Example: If France is near the center of Africa, you'd do something like:
    // { id: 'French-Accent-v2', name: 'French Accented', position: { x: CENTER_X, y: CENTER_Y - 70 } },
    { id: 'Brazilian-Portuguese-v1', name: 'Brazilian Portuguese Accent', position: { x: MAP_X_OFFSET + 100, y: MAP_Y_OFFSET + 150 } },
    { id: 'American-Accent-v4', name: 'American Accent', position: { x: MAP_X_OFFSET + 65, y: MAP_Y_OFFSET + 65 } },
    { id: 'Lat-Am-Spanish-v1', name: 'Latin American Spanish Accent', position: { x: MAP_X_OFFSET + 70, y: MAP_Y_OFFSET + 110 } },
    { id: 'Thai-Inflected-v1', name: 'Thai Accent', position: { x: MAP_X_OFFSET + 250, y: MAP_Y_OFFSET + 100 } },
];

const CountrySelector = ({ onCountrySelect, selectedCountry }) => {
    const svgRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [markerPosition, setMarkerPosition] = useState({
        x: CENTER_X,
        y: CENTER_Y,
    });

    // Convert mouse/touch event to SVG coordinates
    const getSVGCoordinates = (event) => {
        const svg = svgRef.current;
        if (!svg) return { x: 0, y: 0 };
        const rect = svg.getBoundingClientRect();

        // Touch
        if (event.touches && event.touches[0]) {
            return {
                x: event.touches[0].clientX - rect.left,
                y: event.touches[0].clientY - rect.top,
            };
        }
        // Mouse
        return {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top,
        };
    };

    // For the bounding circle approach
    const getDistanceFromCenter = (x, y) =>
        Math.sqrt((x - CENTER_X) ** 2 + (y - CENTER_Y) ** 2);

    // Clamps to the bounding circle if you want to prevent the marker from going too far
    const clampToCircle = (x, y) => {
        const distance = getDistanceFromCenter(x, y);
        if (distance <= RADIUS) {
            return { x, y };
        } else {
            const angle = Math.atan2(y - CENTER_Y, x - CENTER_X);
            const newX = CENTER_X + RADIUS * Math.cos(angle);
            const newY = CENTER_Y + RADIUS * Math.sin(angle);
            return { x: newX, y: newY };
        }
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
        setIsDragging(true);
        const coords = getSVGCoordinates(event);
        setMarkerPosition(clampToCircle(coords.x, coords.y));
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;
        const coords = getSVGCoordinates(event);
        setMarkerPosition(clampToCircle(coords.x, coords.y));
    };

    const finalizeSelection = (x, y) => {
        let closest = null;
        let closestDistance = Infinity;

        COUNTRIES.forEach((c) => {
            const dist = Math.sqrt((c.position.x - x) ** 2 + (c.position.y - y) ** 2);
            if (dist < closestDistance) {
                closestDistance = dist;
                closest = c;
            }
        });

        if (closest) {
            setMarkerPosition({ ...closest.position });
            onCountrySelect(closest.id);
        }
    };

    const handleMouseUp = () => {
        if (!isDragging) return;
        setIsDragging(false);
        finalizeSelection(markerPosition.x, markerPosition.y);
    };

    const handleMouseLeave = () => {
        if (isDragging) {
            setIsDragging(false);
            finalizeSelection(markerPosition.x, markerPosition.y);
        }
    };

    const handleTouchEnd = () => {
        if (!isDragging) return;
        setIsDragging(false);
        finalizeSelection(markerPosition.x, markerPosition.y);
    };

    // If parent changes selectedCountry, snap marker to it
    useEffect(() => {
        const found = COUNTRIES.find((c) => c.id === selectedCountry);
        if (found) {
            setMarkerPosition({ ...found.position });
        }
    }, [selectedCountry]);

    return (
        <div className={styles['country-wheel']}>
            <svg
                ref={svgRef}
                width={SVG_WIDTH}
                height={SVG_HEIGHT}
                viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
                style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleMouseDown}
                onTouchMove={handleMouseMove}
                onTouchEnd={handleTouchEnd}
            >
                {/* The smaller map image, offset to center it in the container */}
                <image
                    x={MAP_X_OFFSET}
                    y={MAP_Y_OFFSET}
                    width={MAP_IMAGE_WIDTH}
                    height={MAP_IMAGE_HEIGHT}
                    xlinkHref={mapImage}
                    preserveAspectRatio="none"
                />

                {/* Optional crosshair lines to help you debug positioning */}
                <line
                    x1={CENTER_X}
                    y1={CENTER_Y - RADIUS}
                    x2={CENTER_X}
                    y2={CENTER_Y + RADIUS}
                    stroke="rgba(255,255,255,0.1)"
                    strokeWidth="1"
                />
                <line
                    x1={CENTER_X - RADIUS}
                    y1={CENTER_Y}
                    x2={CENTER_X + RADIUS}
                    y2={CENTER_Y}
                    stroke="rgba(255,255,255,0.1)"
                    strokeWidth="1"
                />

                {/* The marker */}
                <circle
                    cx={markerPosition.x}
                    cy={markerPosition.y}
                    r="9"
                    fill="#fde4cd"
                    stroke="#313130"
                    strokeWidth="2"
                />
            </svg>

            {/* Display selected country */}
            <div style={{ color: 'white', fontSize: '16px', fontWeight: 'bold', marginTop: '5px' }}>
                {selectedCountry || 'No country selected'}
            </div>
        </div>
    );
};

export default CountrySelector;
