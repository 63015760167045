import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import classes from "../../Assets/Styles/Demo.module.css";
import GradientCarousel from "./GradientCarousel";
import EmotionWheel from "./EmotionWheel";
import TextInputArea from "./TextInputArea";
import { GENERATE_TTS_AUDIO } from '../../MProjectConst';
import { CHARACTERS } from "./DemoConstants";
import CountrySelector from "./CountrySelector";
import logo from "../../Assets/Images/logo3.png"; // Import the logo

const Demo2025 = () => {
    const [activeCharacterIndex, setActiveCharacterIndex] = useState(0);
    const [selectedEmotion, setSelectedEmotion] = useState("Neutral");
    const [selectedCountry, setSelectedCountry] = useState(null); // Default to USA 
    const [inputText, setInputText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Performance metrics state variables
    const [speedMs, setSpeedMs] = useState(null);
    const [cost, setCost] = useState(null);
    const [savings, setSavings] = useState(null);

    // Audio element reference
    const audioRef = useRef(null);

    // Store the last successful request parameters for comparison
    const lastRequestRef = useRef({
        text: "",
        voiceId: "",
        voiceProvider: "",
        emotion: "",
        accent: ""
    });

    // Store the last generated audio URL
    const lastAudioUrlRef = useRef("");

    // Previous state values for comparison
    const prevEmotionRef = useRef(selectedEmotion);
    const prevCountryRef = useRef(selectedCountry);

    // Map characters to images for the carousel
    const images = CHARACTERS.map((char) => char.image);

    // Function to check if the current request is the same as the last one
    const isSameRequest = (currentRequest) => {
        const lastRequest = lastRequestRef.current;
        return (
            currentRequest.text === lastRequest.text &&
            currentRequest.voiceId === lastRequest.voiceId &&
            currentRequest.voiceProvider === lastRequest.voiceProvider &&
            currentRequest.emotion === lastRequest.emotion &&
            currentRequest.accent === lastRequest.accent
        );
    };

    // Handle emotion selection with auto-regeneration
    const handleEmotionSelect = (emotion) => {
        // Only regenerate if the emotion actually changed
        if (emotion !== selectedEmotion) {
            setSelectedEmotion(emotion);

            // Check if we have text to generate with
            if (inputText.trim()) {
                // Use setTimeout to ensure state update completes first
                setTimeout(() => generateAudio(emotion, selectedCountry), 0);
            }
        } else {
            // If it's the same emotion, just replay existing audio
            replayAudioIfAvailable();
        }
    };

    // Handle country selection with auto-regeneration
    const handleCountrySelect = (countryId) => {
        // Only regenerate if the country actually changed
        if (countryId !== selectedCountry) {
            setSelectedCountry(countryId);

            // Check if we have text to generate with
            if (inputText.trim()) {
                // Use setTimeout to ensure state update completes first
                setTimeout(() => generateAudio(selectedEmotion, countryId), 0);
            }
        } else {
            // If it's the same country, just replay existing audio
            replayAudioIfAvailable();
        }
    };

    // Function to replay existing audio if available
    const replayAudioIfAvailable = () => {
        if (lastAudioUrlRef.current && audioRef.current) {
            console.log("No change detected, replaying existing audio");
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            audioRef.current.play().catch((err) => {
                console.warn("Error replaying audio:", err);
            });
        }
    };

    // Generate audio with specific emotion and country
    const generateAudio = async (emotion, country) => {
        if (!inputText.trim()) {
            setError("Please enter some text");
            return;
        }
        setError(null);

        const selectedCharacter = CHARACTERS[activeCharacterIndex];

        const currentRequest = {
            text: inputText,
            voiceId: selectedCharacter.id,
            voiceProvider: selectedCharacter.provider,
            emotion: emotion,
            accent: country
        };

        // Check if this is the same as the last request
        if (isSameRequest(currentRequest) && lastAudioUrlRef.current) {
            console.log("Same request detected, reusing existing audio");
            replayAudioIfAvailable();
            return;
        }

        // If we get here, we need to make a new API call
        setIsLoading(true);

        try {
            // Reset any existing audio to avoid "interrupted by new load request" errors
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.src = "";
                audioRef.current.load(); // ensures the old audio is fully reset
            }

            const response = await axios.post(GENERATE_TTS_AUDIO, currentRequest);

            if (response.data.success && audioRef.current) {
                // Store this request and its audio URL for future reference
                lastRequestRef.current = { ...currentRequest };
                lastAudioUrlRef.current = response.data.audioUrl;

                // Set the audio source and play
                audioRef.current.src = response.data.audioUrl;
                // Attempt to auto-play
                audioRef.current.play().catch((err) => {
                    console.warn("Auto-play error:", err);
                });

                // Update performance metrics from the API response
                if (response.data.cost) {
                    setCost(parseFloat(response.data.cost.amount).toFixed(5));
                }

                // These would come from the API once implemented on the backend
                if (response.data.speedMs) {
                    setSpeedMs(response.data.speedMs);
                } else {
                    // Temporary placeholder until backend provides the data
                    setSpeedMs(18); // 18ms as shown in the example
                }

                if (response.data.savings) {
                    setSavings(response.data.savings);
                } else {
                    // Temporary placeholder until backend provides the data
                    setSavings(35.4); // 35.4% as shown in the example
                }
            } else {
                throw new Error(response.data.message || "Failed to generate audio");
            }
        } catch (err) {
            console.error("Error generating audio:", err);
            setError(err.message || "An error occurred while generating audio");
        } finally {
            setIsLoading(false);
        }
    };

    // Handle the generate button click
    const handleGenerateAudio = () => {
        generateAudio(selectedEmotion, selectedCountry);
    };

    // When the text changes, clear the last request reference
    useEffect(() => {
        // This makes sure we generate new audio when text changes
        lastRequestRef.current.text = "";
    }, [inputText]);

    // When character changes, clear the last request reference
    useEffect(() => {
        // This makes sure we generate new audio when character changes
        lastRequestRef.current.voiceId = "";
    }, [activeCharacterIndex]);

    return (
        <div className={classes["demo-root"]}>
            <div className={classes["demo-container"]}>
                {/* Header */}
                <h1 className={classes["header"]}>An InCantor AI Private Preview</h1>
                <hr className={classes["divider"]} />
                <h2 className={classes["subheader"]}>Text-to-Performance</h2>

                {/* Main Content */}
                <div className={classes["main-content"]}>
                    {/* Emotion Wheel (Left) */}
                    <div className={classes["emotion-selector"]}>
                        <EmotionWheel
                            onEmotionSelect={handleEmotionSelect}
                            selectedEmotion={selectedEmotion}
                        />
                    </div>

                    {/* Carousel (Center) */}
                    <div className={classes["carousel-wrapper"]}>
                        <GradientCarousel
                            images={images}
                            characters={CHARACTERS}
                            activeIndex={activeCharacterIndex}
                            setActiveIndex={setActiveCharacterIndex}
                            isLoading={isLoading}
                        />
                    </div>

                    {/* Country Selector (Right) */}
                    <div className={classes["country-selector"]}>
                        <CountrySelector
                            onCountrySelect={handleCountrySelect}
                            selectedCountry={selectedCountry}
                        />
                    </div>
                </div>

                {/* Text Input & Submit Button */}
                <TextInputArea
                    inputText={inputText}
                    setInputText={setInputText}
                    isLoading={isLoading}
                    onGenerateAudio={handleGenerateAudio}
                    onInsertEmoji={(emoji) => {
                        if (!isLoading) {
                            setInputText(inputText + emoji);
                        }
                    }}
                />

                {/* Error Message */}
                {error && (
                    <div className={classes["error-message"]}>
                        {error}
                    </div>
                )}

                {/* Performance Metrics */}
                {(speedMs !== null || cost !== null || savings !== null) && (
                    <div className={classes["performance-metrics-container"]}>
                        <div className={classes["performance-metrics-row"]}>
                            {speedMs !== null && (
                                <span className={classes["metric"]}>Speed: {speedMs}ms</span>
                            )}
                            {cost !== null && (
                                <span className={classes["metric"]}>Cost: ${cost} (TBD)</span>
                            )}
                        </div>
                        {savings !== null && (
                            <div className={classes["savings-row"]}>
                                <span className={classes["metric"]}>Savings: {savings}%</span>
                            </div>
                        )}
                    </div>
                )}

                {/* Logo in bottom left */}
                <div className={classes["logo-container"]}>
                    <img src={logo} alt="InCantor Logo" className={classes["logo-image"]} />
                </div>
            </div>

            {/* Hidden audio element */}
            <audio ref={audioRef} style={{ display: "none" }} />
        </div>
    );
};

export default Demo2025;